import { useAuthentication } from "hooks/useAuthentication";
import React = require("react");
import { sgwtConnect } from "common/auth/sgwtConnect";

export type AuthenticationContext = ReturnType<typeof useAuthentication>;

export const authenticationContext = React.createContext<AuthenticationContext>(undefined);

export const AuthenticationContextProvider: React.FC = props => {
  sgwtConnect.enablePeriodicalRenewal();

  // eslint-disable-next-line react/prop-types
  return <authenticationContext.Provider value={useAuthentication()}>{props.children}</authenticationContext.Provider>;
};
