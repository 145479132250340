import { FC } from "react";
import React = require("react");
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { map } from "lodash";
import { IdAndLabel } from "common/common.typings";

export interface Props {
  selectedValue?: any;
  isFetching?: boolean;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  options?: Array<IdAndLabel<string>>;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  required?: boolean;
}

export const DropDown: FC<Props> = (props: Props) => {
  const { options, className, style, selectedValue, disabled, onChange, isFetching, required } = props;
  return (
    <div className={`form-group ${className}`}>
      <select
        className="form-control required"
        style={style}
        value={selectedValue}
        disabled={disabled}
        onChange={e => onChange(e)}
        required={required}
      >
        {map(options, key => (
          <option label={key.label} key={key.id} value={key.id} />
        ))}
      </select>
      {isFetching && (
        <div className="input-group-append">
          <span className="input-group-text">
            <FontAwesomeIcon icon={faSync} spin={true} />
          </span>
        </div>
      )}
    </div>
  );
};
