import { UserProfile } from "../../components/entities.typings";
import React = require("react");
import { UserApi } from "../../api/users.api";
import { get } from "lodash";
import { ProblemDetails } from "api/users.api.typings";

export const userContext = React.createContext<UserProfile | ProblemDetails>(undefined);

// eslint-disable-next-line react/prop-types
export const UserContextProvider: React.FC = ({ children }) => {
  const [userState, setUserState] = React.useState<UserProfile | ProblemDetails>({ transitions: [], userProfile: "" });

  const fetchUser = () => {
    UserApi.getUser().then(user => {
      if (get(user, "status") === 403) {
        window.location.href = "https://shared.sgmarkets.com/unauthorized/?redirectUri=https://cprofit.sgmarkets.com/";
      }
      setUserState(user);
    });
  };

  React.useEffect(() => {
    fetchUser();
    return () => {
      setUserState(undefined);
    };
  }, []);

  return <userContext.Provider value={userState}>{children}</userContext.Provider>;
};
