import * as React from "react";
import { Environment } from "common/config/config.typings";

export interface StateProps {
  environment?: Environment;
}

export interface State {
  accountCenter: any;
}

export class SgHeader extends React.Component<StateProps, State> {
  public static getDerivedStateFromProps(_props: StateProps, state: State): State | null {
    if (!state.accountCenter) {
      return null;
    }
    return state;
  }

  private readonly id = "cpr-account-center";

  constructor(props) {
    super(props);
    this.state = { accountCenter: null };
  }

  public render() {
    const { environment } = this.props;
    const env = environment && environment !== "PROD" ? environment : null;
    return (
      <>
        <sgwt-splash-screen id="my-splash-screen" application="c-profit-correction"></sgwt-splash-screen>
        <sgwt-help-center
          id="my-help-center"
          sg-connect-support="sg-connect-v2"
          application-id="c-profit-correction"
        ></sgwt-help-center>
        <sgwt-account-center
          id={this.id}
          environment={env}
          mode="sg-markets"
          language="en"
          hide-current-language={true}
          authentication="sg-connect-v2"
        />
      </>
    );
  }
}
