import { Referential } from "components/entities.typings";

export const QUARTER_REFERENTIALS: Referential[] = [
  { id: "03", label: "March" },
  { id: "06", label: "June" },
  { id: "09", label: "September" },
  { id: "12", label: "December" },
];

export const MONTH_REFERENTIALS: Referential[] = [
  { id: "01", label: "January" },
  { id: "02", label: "February" },
  { id: "03", label: "March" },
  { id: "04", label: "April" },
  { id: "05", label: "May" },
  { id: "06", label: "June" },
  { id: "07", label: "July" },
  { id: "08", label: "August" },
  { id: "09", label: "September" },
  { id: "10", label: "October" },
  { id: "11", label: "November" },
  { id: "12", label: "December" },
];

export const EMPTY_REFERENTIAL: Referential = { id: null, label: "" };

/**
 *  default number of previous years to display in Quarter Month selector
 */
export const DEFAULT_NB_PREV_YEARS = 3;
