import * as React from "react";
import { Label } from "components/common/ReadOnlyField/Label";
import SelectDropDownSearch from "components/common/SelectDropDownSearch/SelectDropDownSearch";
import referentialApi from "api/referentials.api";
import {
  AGENCY_NAME,
  BOOKING_ENTITY,
  BP,
  ERRROR_MESSAGE_A,
  ORIGINATING_ENTITY,
  PO,
  PRODUCT_LABEL,
  PROFIT_CENTER,
  SUB_SUB_BUSINESS_LINE,
  INDICATOR,
  ORIGINATING_COUNTRY,
} from "../utils/correction-modal.const";
import { buildLikeFilter } from "../utils/correction-modal.helper";
import { debounce, get, noop } from "lodash";
import { Referential } from "components/entities.typings";
import { computeUndefiniteArticle } from "utils/strings/stringUtils";

export interface ReferentialPickerProps {
  label: ReferentialLabel;
  disabled: boolean;
  isValid: boolean;
  selected: Referential;
  referentials?: Referential[];
  displayLabel?: boolean;
  labelClassName?: string;
  additionalFilter?: string;
  includeKeys?: boolean;
  defaultSelectedProfitCenter?: (items: Referential[]) => void;
  onSelect: (selected: Referential) => void;
}

const REFERENTIAL = {
  [PRODUCT_LABEL]: "product_label_and_code",
  [AGENCY_NAME]: "agency_name_and_rescode",
  [PO]: "po_lefullname_and_id",
  [BP]: "client_lename_and_id",
  [ORIGINATING_ENTITY]: "fpv_oe_label_and_elrcode",
  [ORIGINATING_COUNTRY]: "fpv_oeresctryname",
  [BOOKING_ENTITY]: "fpv_be_label_and_elrcode",
  [SUB_SUB_BUSINESS_LINE]: "fpv_ssbl",
  [PROFIT_CENTER]: "fpv_pcmnemo",
};

type ReferentialLabel = keyof typeof REFERENTIAL | typeof INDICATOR;

const ReferentialPicker: React.FC<ReferentialPickerProps> = ({
  label,
  disabled,
  selected,
  isValid,
  onSelect,
  referentials,
  displayLabel = true,
  labelClassName = "text-secondary mb-0",
  additionalFilter = "",
  defaultSelectedProfitCenter,
  includeKeys = false,
}: ReferentialPickerProps) => {
  const [items, setItems] = React.useState<Referential[]>(referentials ?? []);

  const concatFilters = (parentFilter: string, referential: string, likeLabel?: string) => {
    if (likeLabel) {
      return `${parentFilter}&${buildLikeFilter(referential, likeLabel)}`;
    } else {
      return additionalFilter;
    }
  };

  let referentialValues: Referential[];

  const handleOnSearch = async (term?: string) => {
    if (!includeKeys) {
      const searchedItems = await referentialApi.getReferentialsValues(
        REFERENTIAL[label],
        additionalFilter.length
          ? concatFilters(additionalFilter, REFERENTIAL[label], term)
          : buildLikeFilter(REFERENTIAL[label], term)
      );
      referentialValues = get(searchedItems, "values").map(referentialValue => {
        return { id: referentialValue, label: referentialValue };
      });
    } else {
      const searchedItems = await referentialApi.getReferentialsKeyValues(
        REFERENTIAL[label],
        additionalFilter.length
          ? concatFilters(additionalFilter, REFERENTIAL[label], term)
          : buildLikeFilter(REFERENTIAL[label], term)
      );
      referentialValues = get(searchedItems, "keysValues").map(referentialValue => {
        return { id: referentialValue.key, label: referentialValue.value };
      });
    }
    setItems(referentialValues);
  };

  React.useEffect(() => {
    (async () => {
      if (additionalFilter) {
        await handleOnSearch();
        defaultSelectedProfitCenter(referentialValues);
      }
    })();
  }, [additionalFilter]);

  return (
    <>
      {displayLabel && <Label displayLabel={label} htmlFor={label} className={labelClassName} />}
      <SelectDropDownSearch
        items={items}
        isValid={isValid}
        invalidMessage={computeUndefiniteArticle(ERRROR_MESSAGE_A, label)}
        onSelection={onSelect}
        selected={selected}
        onChangeSearchTerm={!referentials ? debounce(e => handleOnSearch(e), 500) : noop}
        disabled={disabled}
      />
    </>
  );
};

export default ReferentialPicker;
