import ReferentialApi from "api/referentials.api";
import { BpSharing, Correction, Deal } from "../../../components/entities.typings";
import { CorrectionTypeEnum } from "../../../enums/CorrectionEnums";

export const mapToCorrection = async (deal: Deal): Promise<Correction> => {
  const correctionType = !deal.rwa || isNaN(deal.rwa) ? CorrectionTypeEnum.NBI : CorrectionTypeEnum.RWA;
  let indicatorId = await getIndicatorId(deal.indicator);
  if (!indicatorId) {
    indicatorId = correctionType === CorrectionTypeEnum.RWA ? "-1" : null;
  }

  return {
    valueDate: deal.valueMonth,
    ssBl: deal.ssBl,
    indicator: deal.indicator,
    indicatorId,
    bookingEntity: deal.be,
    originatingEntity: deal.oe,
    poClient: deal.po,
    poClientId: deal.poLeId,
    bpSharing: deal.bpSharing.map(
      (bp): BpSharing => {
        return { bpClient: bp.clientLe, bpClientId: bp.clientLeId, share: bp.sharingPercentage.toString() };
      }
    ),
    profitCenter: deal.pc,
    agencyName: deal.agency,
    productLabel: deal.product,
    correctionType: correctionType,
    dealCode: deal.dealCode,
    isIb: deal.isIb,
  };
};

const getIndicatorId = async (inputLabel: string): Promise<string> => {
  return (await ReferentialApi.getIndicators()).values?.find(({ label }) => label === inputLabel)?.id;
};
