import { ICorrectionApi } from "./corrections.api.typings";
import { Correction, CorrectionsDto } from "../components/entities.typings";
import { createRepository, getAuthenticationHeader } from "./api";
import { getFormData, getFormDataWithJson, returnDownloadUrl } from "./api.helpers";
import { ToastMessage } from "./api.typings";

export const CorrectionApi: ICorrectionApi = {
  getCurrentCorrections: (): Promise<CorrectionsDto> =>
    createRepository().get<CorrectionsDto>("/api/v1/corrections", getAuthenticationHeader()),
  getCorrection: (correctionId: number): Promise<Correction> =>
    createRepository().get<Correction>(`/api/v1/corrections/${correctionId}`, getAuthenticationHeader()),
  getCorrectionByDealCode: (dealcode: string): Promise<Correction> =>
    createRepository().get<Correction>(`/api/v1/corrections/${dealcode}/getByDeal`, getAuthenticationHeader()),
  setCorrectionStatus: (correctionId: number, action: string): Promise<Correction> =>
    createRepository().post<string, Correction>(
      `/api/v1/corrections/${correctionId}/${action}`,
      getAuthenticationHeader(),
      null,
      {
        successMessage: "Status update successfully",
      }
    ),
  async createCorrection(correction: Correction): Promise<Correction> {
    const formData = getFormDataWithJson(correction);

    return await createRepository().post<FormData, Correction>(
      `/api/v1/corrections`,
      getAuthenticationHeader(),
      formData,
      {
        successMessage: "Correction created successfully",
      }
    );
  },
  async updateCorrection(correction: Correction): Promise<Correction> {
    const formData = getFormDataWithJson(correction);

    return await createRepository().put<FormData, Correction>(
      `/api/v1/corrections/${correction.correctionId}`,
      getAuthenticationHeader(),
      formData,
      { successMessage: "Correction updated successfully" }
    );
  },
  async patchCorrection(correction: Correction): Promise<Correction> {
    const formData = getFormData(correction);

    return await createRepository().patch<FormData, Correction>(
      `/api/v1/corrections/${correction.correctionId}`,
      getAuthenticationHeader(),
      formData,
      { successMessage: "Correction updated successfully" }
    );
  },
  async downloadJustification(id: number): Promise<void> {
    await createRepository()
      .getStream(`/api/v1/corrections/${id}/justification`, getAuthenticationHeader(), null, {
        friendlyErrorMessage: "The file download failed",
      } as ToastMessage)
      .then(response => {
        returnDownloadUrl(response);
      });
  },
  async exportCorrections(correctionIdList: number[]): Promise<void> {
    const body = correctionIdList ? JSON.stringify(correctionIdList) : undefined;
    const headers = {
      ...getAuthenticationHeader(),
      Accept: "text/csv",
      "Content-type": "application/json",
    };

    await createRepository()
      .postStream(`/api/v1/corrections/export`, headers, body, {
        friendlyErrorMessage: "The file download failed",
      } as ToastMessage)
      .then(response => {
        returnDownloadUrl(response);
      });
  },
};
