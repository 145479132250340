import { BpSharing, Correction, Referential } from "../../../entities.typings";
import { find, isEmpty, isNumber, map, reduce, values } from "lodash";
import { BpSharingState, ModalState } from "../correction-modal.typings";
import { CorrectionTypeEnum } from "../../../../enums/CorrectionEnums";
import { DateTime } from "luxon";
import { formatNumber } from "common/helpers";
import { MONTH_REFERENTIALS, QUARTER_REFERENTIALS } from "components/common/DatePicker/month-year-selector.const";

/**
 *  checks that the form section state is valid
 * @param state : generic section state
 */
export const isFormValid = (modalState: ModalState): boolean =>
  find(values(modalState.DealInformationSectionState), stateValue =>
    Array.isArray(stateValue)
      ? reduce(
          stateValue,
          (sum, state: BpSharingState) => sum || state.Client.isValid === false || state.Sharing.isValid === false,
          false
        )
      : stateValue.isValid === false
  ) === undefined &&
  find(values(modalState.JustificationSectionState), stateValue => stateValue.isValid === false) === undefined;

/**
 * Build a like filter
 * @param attributeId : attribute to filter on
 * @param term : term to search on attribute values
 */
export const buildLikeFilter = (attributeId: string, term: string): string => `${attributeId}:like:*${term}*`;

/**
 * Build an equal filter
 * @param attributeId : attribute to filter on
 * @param term : term to search on attribute values
 */
export const buildEqualFilter = (attributeId: string, term: string): string => `${attributeId}:${term}`;

export const mapStateFromCorrection = (correction: Correction): ModalState => {
  return {
    JustificationSectionState: {
      Description: { isValid: !isEmpty(correction.description), description: correction.description },
      Justification: {
        isValid: !isEmpty(correction.justificationFile),
        justification: correction.justification,
        justificationFile: correction.justificationFile,
      },
    },
    DealInformationSectionState: {
      OriginatingEntity: {
        isValid: true,
        selected: {
          id: correction.originatingEntity,
          label: correction.originatingEntity,
        },
      },
      BookingEntity: {
        isValid: true,
        selected: {
          id: correction.bookingEntity,
          label: correction.bookingEntity,
        },
      },
      Ssbl: {
        isValid: true,
        selected: {
          id: correction.ssBl,
          label: correction.ssBl,
        },
      },
      BpSharing: map(
        correction.bpSharing?.filter(bp => parseFloat(bp.share)),
        (bp: BpSharing): BpSharingState => {
          return {
            Client: {
              isValid: true,
              selected: {
                id: bp.bpClientId,
                label: bp.bpClient,
              },
            },
            Sharing: {
              isValid: true,
              input: formatNumber(Number(bp.share) * 100),
            },
            OldSharing: formatNumber(Number(bp.share) * 100),
          };
        }
      ),
      PoClient: {
        isValid: true,
        selected: {
          id: correction.poClientId,
          label: correction.poClient,
        },
      },
      AgencyName: {
        isValid: true,
        selected: {
          id: correction.agencyName,
          label: correction.agencyName,
        },
      },
      ProfitCenter: {
        isValid: true,
        selected: {
          id: correction.profitCenter,
          label: correction.profitCenter,
        },
      },
      ValueDate: {
        isValid: true,
        date: DateTime.fromJSDate(new Date(correction.valueDate)).toFormat("MM yyyy"),
      },
      Amount: {
        isValid: isNumber(correction.amount) || correction.correctionType === CorrectionTypeEnum.BP.toString(),
        amount: correction.amount,
      },
      ProductLabel: {
        isValid: true,
        selected: {
          id: correction.productLabel,
          label: correction.productLabel,
        },
      },
      CorrectionType: {
        isValid: true,
        correctionType: correction.correctionType,
      },
      Indicator: {
        isValid:
          correction.correctionType === CorrectionTypeEnum.RWA || correction.indicatorId || correction.indicator
            ? true
            : false,
        selected: {
          id: correction.indicatorId,
          label: correction.indicator,
        },
      },
      IsIB: {
        isValid: true,
        value: correction.isIb,
      },
    },
  };
};

export const isSelected = (selectedId: string): boolean => {
  return !!selectedId;
};

export const emptyFieldValidation = (value: string): boolean => {
  return !isEmpty(value);
};

export const valueMonthValidation = (isTypeRWA: boolean, value: string): boolean => {
  return !!(isTypeRWA ? QUARTER_REFERENTIALS.find(q => q.id === value) : MONTH_REFERENTIALS.find(m => m.id === value));
};

export const amountInputValidation = (value: string): boolean => {
  const pattern = /^[+-]?\d*\.?\d+(?:[Ee][+-]?\d+)?$/;
  return pattern.test(value);
};

export const amountInputErrorMessages = (value: string): string => {
  if (!emptyFieldValidation(value)) {
    return "This field is mandatory";
  } else if (!amountInputValidation(value)) {
    return "Must be a number without whitespaces";
  } else {
    return null;
  }
};

export const dateMonthToDatePickerFormat = (dateMonth: string): string => {
  const datePattern = /^\d{2}\s\d{4}$/;
  return dateMonth?.length && dateMonth.match(datePattern)
    ? `${dateMonth.split(" ")[0] ?? ""} 01 ${dateMonth.split(" ")[1] ?? ""}`
    : "";
};

export const patchCorrectionWithIndicator = (correction: Correction, indicators: Referential[]): Correction => {
  const indicator = indicators.find(
    indic => indic.id === correction.indicatorId || indic.label === correction.indicator
  );
  if (indicator) {
    correction.indicator = indicator.label;
    correction.indicatorId = indicator.id;
  }
  return correction;
};

export const checkSharingSum = (sharing: BpSharingState[]): boolean => {
  const sum = sharing.reduce((a, b) => a + Number(b.Sharing.input) * 100, 0);
  return sum / 100 === 100;
};

export const checkShare = (value: string): boolean => {
  return emptyFieldValidation(value) && Number.parseFloat(value) > 0 && Number.parseFloat(value) <= 100;
};

export const checkAllShares = (bpSharing: BpSharingState[]): boolean => {
  return bpSharing.reduce((a, bp) => a && checkShare(bp.Sharing.input), true);
};

export const getValidationErrorMessage = (bpSharing: BpSharingState[]): string => {
  if (!checkAllShares(bpSharing)) {
    return "A sharing must be between 0 and 100";
  }
  if (!checkSharingSum(bpSharing)) {
    return "Sum of sharing must be equal to 100%";
  }
  return "";
};
