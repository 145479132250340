import { Correction, Referential } from "components/entities.typings";
import { CorrectionType, CorrectionTypeEnum } from "enums/CorrectionEnums";
import { ModalState, ReferentialProps } from "../correction-modal.typings";
import { DEFAULT_STATE } from "../utils/correction-modal.const";
import {
  isSelected,
  emptyFieldValidation,
  dateMonthToDatePickerFormat,
  mapStateFromCorrection,
  checkSharingSum,
  checkShare,
  checkAllShares,
  amountInputValidation,
  valueMonthValidation,
} from "../utils/correction-modal.helper";

interface UpdateReferentialAction {
  type: "update_referential";
  payload: {
    selected: Referential;
    propName: ReferentialProps;
    index?: number;
  };
}

interface UpdateCorrectionTypeAction {
  type: "update_correctiontype";
  payload: CorrectionType;
}

interface UpdateAmountAction {
  type: "update_amount";
  payload: string;
}

interface UpdateValueDateAction {
  type: "update_valuedate";
  payload: string;
}

interface ResetCorrectionAction {
  type: "reset";
}

interface LoadCorrectionAction {
  type: "load";
  payload: Correction;
}
interface AddBpClientAction {
  type: "add_bpClient";
}

interface RemoveBpClientAction {
  type: "remove_bpClient";
  payload: number;
}

interface UpdateSharingAction {
  type: "update_sharing";
  payload: {
    value: string;
    index: number;
  };
}

interface UpdateIsIbSwitchAction {
  type: "switch_isib";
  payload: boolean;
}

export type DealInformationAction =
  | UpdateReferentialAction
  | UpdateCorrectionTypeAction
  | UpdateAmountAction
  | UpdateValueDateAction
  | ResetCorrectionAction
  | LoadCorrectionAction
  | AddBpClientAction
  | RemoveBpClientAction
  | UpdateSharingAction
  | UpdateIsIbSwitchAction;

export const dealInfoReducer = (state: ModalState, action: DealInformationAction): ModalState => {
  switch (action.type) {
    case "update_referential":
      // eslint-disable-next-line no-case-declarations
      const {
        payload: { selected, propName },
      } = action;
      if (action.payload.propName === "Ssbl") {
        return {
          ...state,
          DealInformationSectionState: {
            ...state.DealInformationSectionState,
            Ssbl: { selected, isValid: isSelected(selected.id) },
            ProfitCenter: { selected: { id: null, label: "" }, isValid: true },
          },
        };
      } else if (action.payload.propName === "BpSharing") {
        const bpSharing = [...state.DealInformationSectionState.BpSharing];
        bpSharing[action.payload.index].Client = {
          selected,
          isValid: isSelected(selected.id),
        };
        return {
          ...state,
          DealInformationSectionState: {
            ...state.DealInformationSectionState,
            [propName]: bpSharing,
          },
        };
      } else {
        return {
          ...state,
          DealInformationSectionState: {
            ...state.DealInformationSectionState,
            [propName]: { selected, isValid: isSelected(selected.id) },
          },
        };
      }
    case "update_correctiontype":
      return {
        ...state,
        DealInformationSectionState: {
          ...state.DealInformationSectionState,
          CorrectionType: {
            correctionType: action.payload,
            isValid: emptyFieldValidation(action.payload),
          },
          Indicator:
            action.payload === CorrectionTypeEnum.RWA
              ? { isValid: true, selected: { id: "-1", label: "RWA" } }
              : { isValid: false },
        },
      };
    case "update_amount":
      return {
        ...state,
        DealInformationSectionState: {
          ...state.DealInformationSectionState,
          Amount: {
            amount: action.payload,
            isValid: emptyFieldValidation(action.payload) && amountInputValidation(action.payload),
          },
        },
      };
    case "update_valuedate": {
      return {
        ...state,
        DealInformationSectionState: {
          ...state.DealInformationSectionState,
          ValueDate: {
            date: dateMonthToDatePickerFormat(action.payload),
            isValid:
              emptyFieldValidation(action.payload) &&
              valueMonthValidation(
                state.DealInformationSectionState.CorrectionType.correctionType === CorrectionTypeEnum.RWA,
                action.payload ? action.payload.split(" ")[0] : ""
              ),
          },
        },
      };
    }
    case "load": {
      return mapStateFromCorrection(action.payload);
    }
    case "reset":
      return {
        ...DEFAULT_STATE,
        DealInformationSectionState: {
          ...DEFAULT_STATE.DealInformationSectionState,
          BpSharing: [
            {
              Client: {
                isValid: false,
              },
              Sharing: { isValid: true, input: "100%" },
              OldSharing: "0",
            },
          ],
        },
      };
    case "add_bpClient": {
      const reducedBpSharing = [...state.DealInformationSectionState.BpSharing];
      reducedBpSharing.push({
        Client: {
          selected: { id: "", label: "" },
          isValid: false,
        },
        Sharing: {
          input: "",
          isValid: false,
        },
        OldSharing: "0",
      });
      return {
        ...state,
        DealInformationSectionState: {
          ...state.DealInformationSectionState,
          BpSharing: reducedBpSharing,
        },
      };
    }
    case "remove_bpClient": {
      const reducedBpSharing = [...state.DealInformationSectionState.BpSharing];
      reducedBpSharing.splice(action.payload, 1);
      if (reducedBpSharing.length === 1) {
        reducedBpSharing[0].Sharing.input = "100";
      }
      const sharingValid = checkAllShares(reducedBpSharing) && checkSharingSum(reducedBpSharing);
      reducedBpSharing.forEach(share => (share.Sharing.isValid = sharingValid));
      return {
        ...state,
        DealInformationSectionState: {
          ...state.DealInformationSectionState,
          BpSharing: reducedBpSharing,
        },
      };
    }
    case "update_sharing": {
      const reducedSharing = [...state.DealInformationSectionState.BpSharing];
      reducedSharing[action.payload.index].Sharing = {
        input: action.payload.value,
        isValid: checkShare(action.payload.value),
      };
      const sharingValid = checkAllShares(reducedSharing) && checkSharingSum(reducedSharing);
      reducedSharing.forEach(share => (share.Sharing.isValid = sharingValid));
      return {
        ...state,
        DealInformationSectionState: {
          ...state.DealInformationSectionState,
          BpSharing: reducedSharing,
        },
      };
    }
    case "switch_isib": {
      return {
        ...state,
        DealInformationSectionState: {
          ...state.DealInformationSectionState,
          IsIB: { 
            isValid: true, 
            value: action.payload
          }
        }
      }
    }
    default:
      return state;
  }
};
