/* eslint-disable @typescript-eslint/ban-types */
import { HttpRequestHeader, ToastMessage } from "api/api.typings";
import { toastifyError, toastifySuccess } from "utils/toastify/toasterNotifyer";
import { globalConfiguration } from "common/config/config";
import { sgwtConnect } from "common/auth/sgwtConnect";
import { stringifyParams } from "../utils/strings/stringUtils";
import { getErrorMessage } from "./api.helpers";
import { get } from "lodash";

const DefaultHeader = { source: "correction", "Content-Type": "application/json" };

type Request =
  | {
      method: "GET";
      headers: HttpRequestHeader;
      url: string;
      params: object;
    }
  | {
      method: "POST" | "PUT" | "PATCH";
      headers: HttpRequestHeader;
      url: string;
      body: BodyInit;
    };

function createRequest(request: Request, includeGetDefaultHeader = true) {
  if (request.method === "GET") {
    const { url, params, method, headers } = request;
    const requestUrl = `${globalConfiguration.correctionApiBaseUrl}${url}${stringifyParams(params)}`;
    return fetch(requestUrl, { method, headers: { ...(includeGetDefaultHeader ? DefaultHeader : {}), ...headers } });
  } else {
    const { url, body, method, headers } = request;
    const requestUrl = `${globalConfiguration.correctionApiBaseUrl}${url}`;
    return fetch(requestUrl, { method, headers: { ...headers }, body });
  }
}

async function tryCatch(
  f: () => Promise<Response>,
  toastMessage?: ToastMessage,
  needToastifySuccess = false,
  returnAsJson = true
): Promise<any> {
  try {
    const result = await f();
    if (!result.ok) {
      toastifyError(await getErrorMessage(result, toastMessage));
      return result;
    }
    if (needToastifySuccess) {
      toastifySuccess(toastMessage.successMessage);
    }
    if (returnAsJson) {
      return await result.json();
    }
    return result;
  } catch (error) {
    toastifyError(get(toastMessage, "friendlyErrorMessage") || error);
    return null;
  }
}

export const createRepository = () => ({
  get: <T>(url: string, headers: HttpRequestHeader, params?: object, toastMessage?: ToastMessage): Promise<T> => {
    const getFetch = () => createRequest({ method: "GET", headers, url, params });
    return tryCatch(getFetch, toastMessage);
  },
  put: <B extends BodyInit, R>(
    url: string,
    headers: HttpRequestHeader,
    body: B,
    toastMessage?: ToastMessage
  ): Promise<R> => {
    const putFetch = () => createRequest({ method: "PUT", headers, url, body });
    return tryCatch(putFetch, toastMessage, true);
  },
  post: <B extends BodyInit, R>(
    url: string,
    headers: HttpRequestHeader,
    body: B,
    toastMessage?: ToastMessage
  ): Promise<R> => {
    const postFetch = () => createRequest({ method: "POST", headers, url, body });
    return tryCatch(postFetch, toastMessage, true);
  },
  patch: <B extends BodyInit, R>(
    url: string,
    headers: HttpRequestHeader,
    body: B,
    toastMessage?: ToastMessage
  ): Promise<R> => {
    const patchFetch = () => createRequest({ method: "PATCH", headers, url, body });
    return tryCatch(patchFetch, toastMessage, true);
  },
  getStream: (
    url: string,
    headers: HttpRequestHeader,
    params?: object,
    toastMessage?: ToastMessage
  ): Promise<Response> => {
    const getStreamFetch = () => createRequest({ method: "GET", headers, url, params }, false);
    return tryCatch(getStreamFetch, toastMessage, false, false);
  },
  postStream: (
    url: string,
    headers: HttpRequestHeader,
    body: BodyInit,
    toastMessage?: ToastMessage
  ): Promise<Response> => {
    const postFetch = () => createRequest({ method: "POST", headers, url, body }, false);
    return tryCatch(postFetch, toastMessage, false, false);
  },
});

export const getAuthenticationHeader = (): HttpRequestHeader => ({
  authorization: sgwtConnect.getAuthorizationHeader(),
});
