import { Referential } from "components/entities.typings";
import { DEFAULT_NB_PREV_YEARS } from "./month-year-selector.const";

/**
 *  returns a `Referential[]` of consecutive years
 * @param nbPrevYears number of years to display before `baseYear`, `DEFAULT_NB_PREV_YEARS` by default
 * @param baseYear last year to display, current date year by default
 */
export function getYearReferentials(
  nbPrevYears: number = DEFAULT_NB_PREV_YEARS,
  baseYear: number = new Date().getFullYear()
): Referential[] {
  const output: Referential[] = [];
  let yearCounter = baseYear - nbPrevYears;
  while (yearCounter <= baseYear) {
    output.push({ id: yearCounter.toString(), label: yearCounter.toString() } as Referential);
    yearCounter++;
  }
  return output;
}

export function getMonthYearFromDateString(date: string): string[] {
  if (date) {
    const tmp = date.split(" ");
    return [tmp[0], tmp[tmp.length - 1]];
  }
  return ["", ""];
}

export function getInitYearBasedOnCurrentDate(dateNow: Date) : number {
  const currentMonth = dateNow.getMonth() + 1; //getMonth is zero based
  const currentYear = dateNow.getFullYear();
  return currentMonth < 4 ? currentYear - 1 : currentYear;
}
