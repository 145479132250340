/**
 * centralize all the routes of your application
 */
export const SGMARKETS_URL = "https://cprofit.sgmarkets.com/#/";
export const ROUTES = {
  HOME: "/",
  NOT_FOUND: "/404",
  CORRECTIONS: "/corrections",
  DEALS: "/deals",
  CORRECTIONS_STATUS: "/corrections/status",
};
