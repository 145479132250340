import * as React from "react";
import { NotFound } from "components/layout/NotFound/NotFound";
import { ROUTES } from "common/routes";
import { Navigate, Route, Routes } from "react-router";
import { CorrectionsStatusListContainer } from "components/Correction/CorrectionsList/CorrectionsStatusListContainer";
import { DealsListContainer } from "components/Deal/DealsList/DealsListContainer";

/**
 * contains all the bindings from routes to application components
 * https://reacttraining.com/react-router/web/api/Switch
 */
export const AppRoutes: React.FunctionComponent = () => (
  <Routes>
    <Route path={ROUTES.HOME} element={<Navigate to={ROUTES.CORRECTIONS} />} />
    <Route path={ROUTES.DEALS} element={<DealsListContainer />} />
    <Route path={ROUTES.CORRECTIONS} element={<CorrectionsStatusListContainer />} />
    <Route path={ROUTES.NOT_FOUND} element={<NotFound shown={true} />} />
    <Route path="/*" element={<NotFound shown={true} />} />
  </Routes>
);
