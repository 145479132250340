import { createInstance } from "@datapunt/matomo-tracker-react";
import { globalConfiguration } from "common/config/config";

export const trackerInstance = createInstance({
  urlBase: globalConfiguration.piwikConfig.piwikUrl,
  siteId: parseInt(globalConfiguration.piwikConfig.piwikSiteId),
  trackerUrl: `${globalConfiguration.piwikConfig.piwikUrl}piwik.php`,
  srcUrl: `${globalConfiguration.piwikConfig.piwikUrl}piwik.js`,
  disabled: `${globalConfiguration.piwikConfig.piwikDisabled}` === "true", // optional, false by default. Makes all tracking calls no-ops if set to true.
  linkTracking: false, // optional, default value: true
});
