export enum CorrectionStatus {
  PROPOSED = "Proposed",
  VALIDATED = "Validated",
  CANCELLED = "Cancelled",
  REJECTED = "Rejected",
}

export enum CorrectionTypeEnum {
  NBI = "nbi",
  RWA = "rwa",
  BP = "bp",
}

export type CorrectionType = "nbi" | "rwa" | "bp";
