import { IReferentialApi } from "./referentials.api.typings";
import { Referentials, ReferentialsKeyValues, ReferentialsValues } from "components/entities.typings";
import { createRepository, getAuthenticationHeader } from "./api";

const referentialApi: IReferentialApi = {
  getReferentialsValues: (attributeId: string, filter: string): Promise<ReferentialsValues> =>
    createRepository().get<ReferentialsValues>(
      `/api/v1/referentials/attributes/${attributeId}/values?filter=${filter}`,
      getAuthenticationHeader()
    ),
  getReferentialsKeyValues: (attributeId: string, filter: string): Promise<ReferentialsKeyValues> =>
    createRepository().get<ReferentialsKeyValues>(
      `/api/v1/referentials/attributes/${attributeId}/keyvalues?filter=${filter}`,
      getAuthenticationHeader()
    ),
  getIndicators: (): Promise<Referentials> =>
    createRepository().get<Referentials>("/api/v1/referentials/indicators", getAuthenticationHeader()),
  getCategories: (): Promise<Referentials> =>
    createRepository().get<Referentials>(`/api/v1/referentials/categories`, getAuthenticationHeader()),
};

export default referentialApi;
