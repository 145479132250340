import { IDealApi } from "./deals.api.typings";
import { DealsDto } from "components/entities.typings";
import { createRepository, getAuthenticationHeader } from "./api";

export const DealApi: IDealApi = {
  async loadDealInformation(code: string): Promise<DealsDto> {
    return createRepository().get<DealsDto>(`/api/v1/deals/search/${code}`, getAuthenticationHeader());
  },
  async dealSearch(
    code: string,
    startDate: string,
    endDate: string,
    searchMode: string,
    ssbl?: string,
    po?: string,
    originatingCountry?: string
  ): Promise<DealsDto> {
    const codeFilter = code ? `&Code=${code}` : "";
    const ssblfilter = ssbl ? `&ssbl=${ssbl}` : "";
    const poFilter = po ? `&po=${po}` : "";
    const originatingCountryFilter = originatingCountry ? `&originatingCountry=${originatingCountry}` : "";

    return createRepository().get<DealsDto>(
      `/api/v1/deals?StartDate=${startDate}&EndDate=${endDate}&searchMode=${searchMode}${codeFilter}${ssblfilter}${poFilter}${originatingCountryFilter}`,
      getAuthenticationHeader()
    );
  },
};
