import { IconEdit } from "../../../components/common/Icon/Icon";
import { CorrectionStatus } from "../../../enums/CorrectionEnums";
import { UserProfile } from "../../../enums/UserProfileEnum";
import { userContext } from "../../../hooks/contexts/UserContext";
import { get } from "lodash";
import React = require("react");

export interface Props {
  onclick: () => void;
  status: string;
}

export const CorrectionEditAction: React.FC<Props> = ({ onclick, status }: Props) => {
  const user = React.useContext(userContext);
  const userProfile = get(user, "userProfile");

  return (
    (userProfile === UserProfile.SUPER_USER || status === CorrectionStatus.PROPOSED) && (
      <IconEdit onClick={onclick} size="sm" className="btn px-2" />
    )
  );
};
