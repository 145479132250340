import { FC, useState } from "react";
import React = require("react");
import { DealsTable } from "./DealsTable";
import CorrectionEditor from "../../Correction/CorrectionEditor/CorrectionEditor";
import { DealSearchFilters, DealSearchFiltersState } from "../DealsSearch/DealSearchFilters";
import { mapToGrid } from "../Helper/DealList.helper";
import { mapToCorrection } from "../Helper/DealToCorrection.helper";
import { GridFilter } from "./GridFilter";
import { Loading } from "../../../components/common/Loading/Loading";
import { CorrectionType, CorrectionTypeEnum } from "../../../enums/CorrectionEnums";
import { Deal, deaLSearchModes } from "../../../components/entities.typings";
import { DealApi } from "../../../api/deals.api";
import { Button } from "../../../components/common/Buttons/Button";
import { dealFeatures } from "utils/logger/features/deal.features";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import createLogger from "utils/logger/logger.helper";
import { getInitYearBasedOnCurrentDate } from "components/common/DatePicker/month-year-selector.helper";
import { getDealSearchEndDate } from "../Helper/DealSearchFilters.helper";
import { DEAL_SEARCH_MODE } from "enums/DealEnum";
export interface DealSearchFiltersProps {
  onSearch: (
    code: string,
    startDate: string,
    endDate: string,
    searchMode: deaLSearchModes,
    ssbl?: string,
    originatingCountry?: string,
    po?: string
  ) => void;
}

export const INIT_STATE: DealSearchFiltersState = {
  startDate: { isValid: true, date: `${getInitYearBasedOnCurrentDate(new Date())}-01-01` },
  endDate: { isValid: true, date: getDealSearchEndDate(new Date()) },
  code: { isValid: false, value: "" },
  ssbl: { isValid: false },
  originatingCountry: { isValid: false },
  po: {
    dropdownState: {
      isValid: false,
      selected: undefined,
    },
    clientId: undefined,
  },
};

export const DealsListContainer: FC = () => {
  const [dealSearchFiltersState, SetDealSearchFiltersState] = React.useState<DealSearchFiltersState>(INIT_STATE);
  const [data, setData] = useState({ lines: [], totalPendingNbi: 0, totalOldNbi: 0 });
  const [loading, setLoading] = useState({ isFetching: false });
  const [gridApi, setGridApi] = useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [correction, setCorrection] = React.useState(null);
  const [disableExport, setDisableExport] = React.useState(true);
  const [searchMode, setSearchMode] = React.useState<DEAL_SEARCH_MODE>(DEAL_SEARCH_MODE.dealCode);
  const [totalNBI, setTotalNbi] = React.useState(0);

  const { trackEvent } = useMatomo();
  const logger = createLogger();

  const fetchDeals = (
    dealcode: string,
    startDate: string,
    endDate: string,
    searchMode: string,
    ssbl?: string,
    originatingCountry?: string,
    po?: string
  ) => {
    setLoading({ isFetching: true });
    DealApi.dealSearch(dealcode, startDate, endDate, searchMode, ssbl, po, originatingCountry)
      .then(loadedDeal => {
        const dealsList = loadedDeal.lines.map(element => mapToGrid(element));
        setData({
          lines: dealsList,
          totalPendingNbi: loadedDeal.totalPendingNbi,
          totalOldNbi: loadedDeal.totalOldNbi,
        });
        setLoading({ isFetching: false });
        let sum = 0;
        loadedDeal.lines.forEach(line => {
          if (!isNaN(line.nbi)) {
            sum += line.nbi;
          }
        });
        setTotalNbi(sum);
      })
      .catch(() => setLoading({ isFetching: false }));
  };

  const onGridReady = params => {
    setGridApi(params.api);

    params.api.sizeColumnsToFit();
    window.addEventListener("resize", function() {
      setTimeout(function() {
        params.api.sizeColumnsToFit();
      });
    });
  };

  const onGridFilterChanged = event => {
    const value = event.target.value;
    gridApi.setQuickFilter(value);
    setDisableExport(gridApi.rowModel.rowsToDisplay.length === 0);

    if (gridApi.rowModel.rowsToDisplay.length === 0) {
      gridApi.showNoRowsOverlay();
    } else {
      gridApi.hideOverlay();
    }
  };

  const onEditAmount = async (deal: Deal, type: CorrectionTypeEnum) => {
    setLoading({ isFetching: true });
    const newCorrection = await mapToCorrection(deal);
    setLoading({ isFetching: false });
    newCorrection.correctionType = type.toString() as CorrectionType;
    newCorrection.isDealCorrection = true;
    setCorrection(newCorrection);
    setShowModal(true);
  };

  const onEditBp = async (deal: Deal) => {
    setLoading({ isFetching: true });
    const newCorrection = await mapToCorrection(deal);
    setLoading({ isFetching: false });
    newCorrection.isDealCorrection = true;
    newCorrection.correctionType = CorrectionTypeEnum.BP.toString() as CorrectionType;
    newCorrection.amount = "";
    setCorrection(newCorrection);
    setShowModal(true);
  };

  const onShowModalClick = () => {
    setCorrection(null);
    setShowModal(true);
    trackEvent(dealFeatures.dealListCreateCorrection);
  };

  const onCloseModalClick = () => {
    setShowModal(false);
  };

  const onExportClick = () => {
    logger.functional("Deal search screen", "Export deals button clicked", "CPR-CORRECTION-UI");
    gridApi.exportDataAsExcel();
  };

  React.useEffect(() => {
    setDisableExport(data?.lines.length === 0 || gridApi?.rowModel.rowsToDisplay.length === 0);
  }, [data, gridApi]);

  return (
    <>
      <Loading isVisible={loading.isFetching} />
      <div className="container-fluid mt-2">
        <CorrectionEditor
          showModalWindow={showModal}
          onCloseModalClick={onCloseModalClick}
          correction={correction}
          dealSearchFiltersState={dealSearchFiltersState}
          fetchDeals={fetchDeals}
          searchMode={searchMode}
        />
        <div>
          <DealSearchFilters
            onSearch={fetchDeals}
            dealSearchFiltersState={dealSearchFiltersState}
            setDealSearchFiltersState={(searchMode: DealSearchFiltersState) => SetDealSearchFiltersState(searchMode)}
            searchMode={searchMode}
            setSearchMode={(searchMode: DEAL_SEARCH_MODE) => setSearchMode(searchMode)}
          />
          <hr />
          <div className="row mb-1">
            <div className="col-5 col-md-4 col-xxl-2">
              <GridFilter onGridFilterChanged={onGridFilterChanged} />
            </div>
            <div className="col-2">
              <Button
                text="Export"
                className="btn btn-md btn-secondary"
                disabled={disableExport}
                onClick={onExportClick}
              />
            </div>
            <div className="col-5 col-md-6 col-xxl-8 text-right">
              <Button text="New Correction" className="btn btn-md btn-success" onClick={onShowModalClick} />
            </div>
          </div>
          <DealsTable
            onGridReady={onGridReady}
            deals={data.lines}
            totalPendingNbi={data.totalPendingNbi}
            totalOldNbi={data.totalOldNbi}
            totalNBI={totalNBI}
            onEditNbi={deal => onEditAmount(deal, CorrectionTypeEnum.NBI)}
            onEditRwa={deal => onEditAmount(deal, CorrectionTypeEnum.RWA)}
            onEditBp={deal => onEditBp(deal)}
          />
        </div>
      </div>
    </>
  );
};
