import { Label } from "../../../common/ReadOnlyField/Label";
import * as React from "react";
import { JUSTIFICATION, DESCRIPTION } from "../utils/correction-modal.const";
import { DescriptionState, JustificationState } from "../correction-modal.typings";
import { FileUploader } from "../../../common/FileUploader/FileUploader";
import { Button } from "../../../common/Buttons/Button";
import { IconExport } from "../../../common/Icon/Icon";
import { CorrectionApi } from "../../../../api/corrections.api";
import { InvalidFeedback } from "../../../common/ValidationMessage/InvalidFeedback";

export interface JustificationSectionProps {
  justification: JustificationState;
  description: DescriptionState;
  correctionId?: number;
  onDescriptionChange: (description: string) => void;
  onSelectFileChange: (file: File) => void;
  onFileRemove: () => void;
}

const JustificationSection: React.FunctionComponent<JustificationSectionProps> = ({
  justification,
  description,
  correctionId,
  onDescriptionChange,
  onSelectFileChange,
  onFileRemove,
}: JustificationSectionProps) => {
  const [fileRemoved, setFileRemoved] = React.useState(false);

  const onDownloadFile = () => {
    CorrectionApi.downloadJustification(correctionId);
  };
  return (
    <>
      <h5>Justification</h5>
      <hr />
      <div className="row mb-2">
        <div className="col-md-12 form-group">
          <Label displayLabel={JUSTIFICATION} htmlFor={JUSTIFICATION} className="text-secondary mb-1" />
          <div className="row text-center">
            <div className={`${correctionId ? "col-md-10" : "col-md-12"}`}>
              <FileUploader
                isValid={justification.isValid}
                onFileSelectSuccess={file => onSelectFileChange(file)}
                onFileRemove={() => {
                  onFileRemove();
                  setFileRemoved(true);
                }}
                fileAlreadyExist={justification.justificationFile != null ? justification.justificationFile : null}
                placeholder="Drag file"
              />
            </div>
            {correctionId && (
              <div className="col-md-2">
                <Button
                  text="Download"
                  className="btn btn-md btn-secondary mt-1"
                  onClick={onDownloadFile}
                  disabled={!justification.isValid || fileRemoved}
                >
                  <IconExport />
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-12 form-group">
          <Label displayLabel={DESCRIPTION} htmlFor={DESCRIPTION} className="text-secondary mb-0" />
          <textarea
            className={`form-control ${description.isValid ? "" : "is-invalid"}`}
            onChange={e => onDescriptionChange(e.target.value)}
            placeholder={DESCRIPTION}
            rows={4}
            cols={50}
            maxLength={500}
            value={description.description}
          />
          {!description.isValid && <InvalidFeedback errorMessage={description.errorMessage} errorType={"danger"} />}
        </div>
      </div>
    </>
  );
};

export default JustificationSection;
