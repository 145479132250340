import { FC } from "react";
import React = require("react");
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface Props {
  type?: "button" | "submit" | "reset";
  icon?: IconProp;
  text?: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  tooltip?: string;
  disabled?: boolean;
  children?: React.ReactNode;
}

export const Button: FC<Props> = (props: Props) => {
  const { className, onClick, tooltip, text, icon, children, disabled } = props;
  return (
    <span className="inline-block" tabIndex={0} data-toggle="tooltip" title={tooltip}>
      <button
        type={props.type}
        className={`btn ${className ?? ""}`}
        data-toggle="tooltip"
        data-placement="top"
        title={tooltip}
        onClick={onClick}
        disabled={disabled}
      >
        {icon && <FontAwesomeIcon icon={icon} className="mr-3" />}
        {text}
        {children}
      </button>
    </span>
  );
};
