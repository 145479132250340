import * as React from "react";
import { differenceBy, get, isEqual } from "lodash";
import { Modal } from "components/common/Modal/Modal";
import { Correction, Referential } from "components/entities.typings";
import {
  checkSharingSum,
  isFormValid,
  mapStateFromCorrection,
  patchCorrectionWithIndicator,
} from "./utils/correction-modal.helper";
import { DEFAULT_STATE } from "./utils/correction-modal.const";
import DealInformationsSection from "./components/DealInformationSection";
import JustificationSection from "./components/JustificationSection";
import referentialApi from "../../../api/referentials.api";
import { CorrectionApi } from "../../../api/corrections.api";
import { mapModalStateToCreateCorrection, mapModalStateToUpdateCorrection } from "./utils/correction-modal.mapper";
import { correctionReducer } from "./reducers/correction-modal.reducer";
import { correctionFeatures } from "utils/logger/features/correction.features";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { CorrectionTypeEnum } from "enums/CorrectionEnums";
import { DealSearchFiltersState } from "components/Deal/DealsSearch/DealSearchFilters";
import { getSearchModeId } from "components/Deal/Helper/DealSearchFilters.helper";
import { DEAL_SEARCH_MODE } from "enums/DealEnum";

export interface Props {
  show: boolean;
  correction: Correction;
  onClose: () => void;
  onSubmit: () => void;
  dealSearchFiltersState?: DealSearchFiltersState;
  searchMode?: DEAL_SEARCH_MODE;
  fetchDeals?: (
    dealcode: string,
    startDate: string,
    endDate: string,
    searchMode: string,
    ssbl?: string,
    originatingCountry?: string,
    po?: string
  ) => void;
}

const CorrectionModal: React.FC<Props> = ({
  show,
  correction,
  onClose,
  onSubmit,
  fetchDeals,
  dealSearchFiltersState,
  searchMode,
}: Props) => {
  const [isModalValid, setIsModalValid] = React.useState<boolean>(false);
  const [indicators, setIndicators] = React.useState<Referential[]>([]);
  const [modalState, dispatch] = React.useReducer(correctionReducer, DEFAULT_STATE);
  const { trackEvent } = useMatomo();
  const [bpSharingIncompleteCheck, setBpSharingIncompleteCheck] = React.useState(false);
  const initModalState = React.useMemo(() => (correction ? mapStateFromCorrection(correction) : null), [correction]);

  React.useEffect(() => {
    if (initModalState?.DealInformationSectionState.BpSharing.length) {
      setBpSharingIncompleteCheck(!checkSharingSum(initModalState.DealInformationSectionState.BpSharing));
    } else {
      setBpSharingIncompleteCheck(false);
    }
  }, [initModalState]);

  React.useEffect(() => {
    setIsModalValid(!isEqual(initModalState, modalState) && isFormValid(modalState));
  }, [modalState, initModalState]);

  React.useEffect(() => {
    if (!indicators.length) {
      (async function fetchIndicators() {
        const items = await referentialApi.getIndicators();
        setIndicators(get(items, "values"));
      })();
    }
  }, []);

  React.useEffect(() => {
    if (correction) {
      correction = patchCorrectionWithIndicator(correction, indicators);
      dispatch({ type: "load", payload: correction });
    } else {
      dispatch({ type: "reset" });
    }
  }, [correction]);

  const completeBpSharing = (actualCorrection: Correction, correctionToUpdate: Correction) => {
    if (!actualCorrection) {
      return;
    }
    const oldBps = differenceBy(actualCorrection.bpSharing, correctionToUpdate.bpSharing, "bpClient");
    oldBps.forEach(bp => {
      bp.oldShare = bp.share;
      bp.share = "0";
    });

    correctionToUpdate.bpSharing.forEach(bp => {
      if (!actualCorrection.bpSharing.find(p => p.bpClient === bp.bpClient)) {
        bp.oldShare = "0";
      }
    });
    correctionToUpdate.bpSharing = correctionToUpdate.bpSharing.concat(oldBps);
  };

  const handleCreateCorrection = async () => {
    if (isFormValid(modalState)) {
      const dealCode = correction?.isDealCorrection ? correction.dealCode : "";
      const correctionToUpdate = mapModalStateToCreateCorrection(modalState, dealCode);
      completeBpSharing(correction, correctionToUpdate);
      await CorrectionApi.createCorrection(correctionToUpdate);
      handleSubmitModal();
      trackEvent(correctionFeatures.correctionSubmitCreateCorrection);
      if (searchMode === DEAL_SEARCH_MODE.dealCode || searchMode === DEAL_SEARCH_MODE.isinCode) {
        fetchDeals(
          dealSearchFiltersState.code.value,
          dealSearchFiltersState.startDate.date,
          dealSearchFiltersState.endDate.date,
          getSearchModeId(searchMode)
        );
      } else {
        if (
          dealSearchFiltersState.ssbl.selected?.label ||
          dealSearchFiltersState.po.dropdownState.selected?.label ||
          dealSearchFiltersState.originatingCountry.selected?.label
        ) {
          fetchDeals(
            dealSearchFiltersState.code.value,
            dealSearchFiltersState.startDate.date,
            dealSearchFiltersState.endDate.date,
            getSearchModeId(searchMode),
            dealSearchFiltersState.ssbl.selected?.label,
            dealSearchFiltersState.originatingCountry.selected?.label,
            dealSearchFiltersState.po.dropdownState.selected?.label
          );
        }
      }
    }
  };

  const handleUpdateCorrection = async () => {
    if (isFormValid(modalState)) {
      const correctionToUpdate = mapModalStateToUpdateCorrection(modalState, correction.correctionId);
      await CorrectionApi.updateCorrection(correctionToUpdate);
      handleSubmitModal();
      trackEvent(correctionFeatures.correctionSubmitUpdateCorrection);
    }
  };

  const handleSubmitModal = () => {
    onClose();
    onSubmit();
    dispatch({ type: "reset" });
  };

  const handleCloseModal = () => {
    onClose();
    dispatch({ type: "reset" });
  };

  const modalTitle = React.useMemo(() => {
    if (!correction) {
      return "New correction";
    }
    return correction.correctionId
      ? `Edit correction : ${correction.correctionId}`
      : `Correction of deal ${correction.dealCode}`;
  }, [correction]);

  return (
    <Modal
      size="lg"
      cancelLabel="Cancel"
      backdropClose={false}
      isLoading={false}
      title={modalTitle}
      show={show}
      submitLabel={correction?.correctionId ? "Update" : "Create"}
      isSubmitValid={
        isModalValid && !(correction?.correctionType === CorrectionTypeEnum.BP && bpSharingIncompleteCheck)
      }
      onCancel={handleCloseModal}
      onClose={handleCloseModal}
      onSubmit={correction?.correctionId ? handleUpdateCorrection : handleCreateCorrection}
    >
      {bpSharingIncompleteCheck && (
        <div className="alert alert-outline-warning" role="alert">
          Some data has been hidden as you don&apos;t have the visibility on all the business provider&apos;s sharing.{" "}
          {correction?.correctionType === CorrectionTypeEnum.BP && (
            <>
              Please contact <a href="mailto:list.par-dfn-client-profitability@sgcib.com">DFIN/FNA</a> to perform the BP
              sharing correction
            </>
          )}
        </div>
      )}
      <DealInformationsSection
        dealCode={correction?.dealCode}
        indicators={indicators}
        selectedDealInformation={modalState.DealInformationSectionState}
        editMode={!!correction?.correctionId}
        isDealCorrection={correction?.isDealCorrection}
        onSelect={(selected, propName, index) =>
          dispatch({ type: "update_referential", payload: { selected, propName, index } })
        }
        onCorrectionTypeSelect={payload => dispatch({ type: "update_correctiontype", payload })}
        onAmountSelect={payload => dispatch({ type: "update_amount", payload })}
        onValueDateSelected={payload => dispatch({ type: "update_valuedate", payload })}
        onAddBp={() => dispatch({ type: "add_bpClient" })}
        removeBpClient={payload => dispatch({ type: "remove_bpClient", payload })}
        onSharingSelect={(value, index) => dispatch({ type: "update_sharing", payload: { value, index } })}
        onIsIbSelect={payload => dispatch({ type: "switch_isib", payload })}
      />
      <JustificationSection
        correctionId={get(correction, "correctionId")}
        justification={modalState.JustificationSectionState.Justification}
        description={modalState.JustificationSectionState.Description}
        onDescriptionChange={payload => dispatch({ type: "update_description", payload })}
        onSelectFileChange={payload => dispatch({ type: "update_file", payload })}
        onFileRemove={() => dispatch({ type: "remove_file" })}
      />
    </Modal>
  );
};

export default CorrectionModal;
