import { FC } from "react";
import React = require("react");
import { SearchInput } from "../../../components/common/SearchInput/SearchInput";

export interface Props {
  onFilterTextBoxChanged: React.FormEventHandler;
}

export const CorrectionsFilter: FC<Props> = (props: Props) => {
  const { onFilterTextBoxChanged } = props;

  return (
    <SearchInput
      id="correction-filter"
      iconName="search"
      onChange={onFilterTextBoxChanged}
      className="form-control"
      placeHoldertext="Search by keyword"
    />
  );
};
