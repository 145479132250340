import * as React from "react";
import { Loading } from "components/common/Loading/Loading";
import { If } from "@dcc-cli/ic-shared-components";
import { sgwtConnect } from "common/auth/sgwtConnect";
import { useAuthenticationContext } from "hooks/contexts/useAuthenticationContext";

// eslint-disable-next-line react/prop-types
export const Authentication: React.FC = ({ children }) => {
  const { authState: authentication } = useAuthenticationContext();

  // This is used by the account center :
  // https://sgithub.fr.world.socgen/DT-Widgets/sgwt-widgets/blob/master/src/sgwt-account-center/README.md#authentication
  window.sgwtConnect = sgwtConnect;

  // eslint-disable-next-line react/no-children-prop
  return <If condition={authentication.loggedIn} children={children} elseComponent={<Loading />} />;
};
