import { getInitYearBasedOnCurrentDate } from "components/common/DatePicker/month-year-selector.helper";
import { deaLSearchModes } from "components/entities.typings";
import { DEAL_SEARCH_MODE } from "enums/DealEnum";
import { DateTime } from "luxon";

// We should not manage searchMode like below. Existing code is alreading managing it like that. We should refactor this.
export const getSearchModeId = (dealCode: DEAL_SEARCH_MODE): deaLSearchModes => {
  switch (dealCode) {
    case "Deal Code":
      return "dealCode";
    case "ISIN Code":
      return "isinCode";
    default:
      return "otherCriteria";
  }
};

export const isValid = (a: boolean, b: boolean): boolean => {
  return a || b;
};

export const getDealSearchEndDate = (dateNow: Date) => {
  const year = getInitYearBasedOnCurrentDate(dateNow);
  if (year === dateNow.getFullYear()) {
    return DateTime.fromJSDate(dateNow).toFormat("yyyy-MM-dd");
  } else {
    return `${year}-12-31`;
  }
};
