import { Referential } from "../../entities.typings";
import * as React from "react";
import SelectDropDownSearch from "../SelectDropDownSearch/SelectDropDownSearch";
import { EMPTY_REFERENTIAL, MONTH_REFERENTIALS, QUARTER_REFERENTIALS } from "./month-year-selector.const";
import { getMonthYearFromDateString, getYearReferentials } from "./month-year-selector.helper";
import { ERRROR_MESSAGE_A } from "components/Correction/CorrectionModal/utils/correction-modal.const";
import { Label } from "../ReadOnlyField/Label";
import { valueMonthValidation } from "components/Correction/CorrectionModal/utils/correction-modal.helper";

interface Props {
  value?: string;
  useQuarter: boolean;
  label?: string;
  onMonthChange: (item: string) => void;
  onYearChange: (item: string) => void;
  disabled?: boolean;
}

export const MonthYearSelector: React.FunctionComponent<Props> = ({
  value,
  useQuarter,
  label,
  onMonthChange,
  onYearChange,
  disabled,
}: Props) => {
  const yearsList = getYearReferentials();
  const [valueMonth, valueYear] = getMonthYearFromDateString(value);
  const month = valueMonth
    ? (useQuarter
        ? QUARTER_REFERENTIALS.find(q => q.id === valueMonth)
        : MONTH_REFERENTIALS.find(m => m.id === valueMonth)) ?? EMPTY_REFERENTIAL
    : EMPTY_REFERENTIAL;
  const year = valueYear ? yearsList.find(y => y.id === valueYear) ?? EMPTY_REFERENTIAL : EMPTY_REFERENTIAL;

  return (
    <>
      <Label displayLabel={label} htmlFor={label} className="text-secondary mb-0" />
      <div className="row mb-2">
        <div className="col-md-7 pr-2 mr-0">
          <SelectDropDownSearch
            size={"xs"}
            placeHolder={useQuarter ? "Quarter" : "Month"}
            items={useQuarter ? QUARTER_REFERENTIALS : MONTH_REFERENTIALS}
            isValid={month.id ? valueMonthValidation(useQuarter, month.id) : false}
            selected={month}
            onSelection={(item: Referential) => onMonthChange(item.id)}
            disabled={disabled}
            invalidMessage={`${ERRROR_MESSAGE_A} ${useQuarter ? "Quarter" : "Month"}`}
          />
        </div>
        <div className="col-md-5 pl-0 ml-0">
          <SelectDropDownSearch
            size={"xs"}
            placeHolder="Year"
            items={yearsList}
            isValid={year.id ? true : false}
            selected={year}
            onSelection={(item: Referential) => onYearChange(item.id)}
            disabled={disabled}
            invalidMessage={`${ERRROR_MESSAGE_A} Year`}
          />
        </div>
      </div>
    </>
  );
};
