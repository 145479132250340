import { find, isEmpty, isNull, values } from "lodash";

/**
 *  checks that the form section state is valid
 * @param state : generic section state
 */
export const isFormValid = <T>(state: T) =>
  find(values(state), stateValue => stateValue.isValid === false) === undefined;

export const textAreaErrorMessage = (text: string): string => {
  if (isEmpty(text)) {
    return "Please enter text";
  }
  if (text.length > 300) {
    return "Max. length 300 characters";
  }
  return null;
};

export const isTextAreaValid = (text: string): boolean => {
  return isNull(textAreaErrorMessage(text));
};
