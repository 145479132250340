import * as React from "react";
import { Footer } from "components/layout/Footer/Footer";
import { Header } from "components/layout/Header/Header";
import { toast } from "react-toastify";

// eslint-disable-next-line react/prop-types
export const MainLayout: React.FC = ({ children }) => {
  toast.configure({
    hideProgressBar: true,
    position: "top-center",
  });
  return (
    <>
      <Header />
      <div className="container-fluid">{children}</div>
      <Footer className={"fixed-bottom"} />
    </>
  );
};
