import { formatFileName } from "common/helpers";
import { Correction } from "components/entities.typings";
import { forEach, get, isEmpty, keys, map } from "lodash";
import { ToastMessage } from "./api.typings";

export const getErrorMessage = async (response: Response, toastMessage?: ToastMessage) => {
  const toast = get(toastMessage, "friendlyErrorMessage");
  if (toast) {
    return toast;
  }

  const responseObject = JSON.parse(await response.text());
  return !isEmpty(responseObject.detail)
    ? responseObject.detail
    : !isEmpty(response.statusText)
    ? response.statusText
    : responseObject.title;
};

export const getFormData = (correction: Correction): FormData => {
  const formData = new FormData();

  map(keys(correction), key => {
    if (correction[key] && Array.isArray(correction[key])) {
      forEach(correction[key], value => formData.append(key, value));
    } else {
      formData.append(key, correction[key]);
    }
  });
  return formData;
};

export const getFormDataWithJson = (correction: Correction): FormData => {
  const formData = new FormData();
  formData.append(
    "Correction",
    JSON.stringify(correction, (key, value) =>
      key === "justification" ? undefined : typeof value === "undefined" ? "undefined" : value
    )
  );
  formData.append("Justification", correction.justification);
  return formData;
};

export const returnDownloadUrl = (response: Response) => {
  const content = response.headers.get("Content-Disposition");
  const formatedFileName = formatFileName(content);
  response.blob().then(blob => {
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = formatedFileName;
    a.click();
  });
};
