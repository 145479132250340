import * as React from "react";
import { CorrectionType, CorrectionTypeEnum } from "enums/CorrectionEnums";
import { noop } from "lodash";

export interface CorrectionTypeSwitcherProps {
  disabled: boolean;
  selectedValue: string;
  onClick: (value: CorrectionType) => void;
}

const CorrectionTypeSwitcher: React.FC<CorrectionTypeSwitcherProps> = ({
  disabled,
  selectedValue,
  onClick,
}: CorrectionTypeSwitcherProps) => {
  const handleOnClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "nbi" || value === "rwa") {
      onClick(value);
    }
  };
  return (
    <div className="btn-group btn-group-toggle mt-3" role="group" aria-label="toggle buttons single select">
      <>
        {[CorrectionTypeEnum.NBI, CorrectionTypeEnum.RWA].map(correctionType => (
          <label
            key={correctionType}
            className={`btn btn-toggle-primary p-2 pr-3 pl-3 ${selectedValue === correctionType ? "active" : ""} ${
              disabled ? "disabled" : ""
            }`}
          >
            <input
              checked={selectedValue === correctionType}
              type="radio"
              name={`radio${correctionType}`}
              id={`radio${correctionType}`}
              value={correctionType}
              disabled={disabled}
              onChange={!disabled ? handleOnClick : noop}
            />
            {correctionType.toUpperCase()}
          </label>
        ))}
      </>
    </div>
  );
};

export default CorrectionTypeSwitcher;
