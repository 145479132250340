import { SearchInput } from "../../../components/common/SearchInput/SearchInput";
import { FC } from "react";
import React = require("react");

export interface Props {
  onGridFilterChanged: React.FormEventHandler;
}

export const GridFilter: FC<Props> = (props: Props) => {
  const { onGridFilterChanged } = props;

  return (
    <SearchInput
      id="grid-filter"
      iconName="search"
      onChange={onGridFilterChanged}
      className="form-control"
      placeHoldertext="Search by keyword"
    />
  );
};
