import { NavLink, NavLinkProps } from "react-router-dom";
import * as React from "react";

export interface Props extends NavLinkProps {
  containerClass?: string;
}
export const NavItemContainer: React.FunctionComponent<Props> = ({ to, containerClass, children }: Props) => (
  <li className={containerClass ? containerClass : "nav-item"}>
    <NavLink to={to} className="nav-link pt-3">
      {children}
    </NavLink>
  </li>
);
