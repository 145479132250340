import { isTextAreaValid, textAreaErrorMessage } from "components/Correction/CorrectionsList/correction-details.helper";
import { ModalState } from "../correction-modal.typings";

interface RemoveFileAction {
  type: "remove_file";
}

interface UpdateFileAction {
  type: "update_file";
  payload: File;
}

interface UpdateDescriptionAction {
  type: "update_description";
  payload: string;
}

export type JustificationAction = RemoveFileAction | UpdateFileAction | UpdateDescriptionAction;

export const justificationReducer = (state: ModalState, action: JustificationAction): ModalState => {
  switch (action.type) {
    case "update_file":
      return {
        ...state,
        JustificationSectionState: {
          ...state.JustificationSectionState,
          Justification: { justification: action.payload, isValid: !!action.payload },
        },
      };
    case "remove_file":
      return {
        ...state,
        JustificationSectionState: {
          ...state.JustificationSectionState,
          Justification: { justification: null, isValid: false },
        },
      };
    case "update_description":
      return {
        ...state,
        JustificationSectionState: {
          ...state.JustificationSectionState,
          Description: {
            description: action.payload,
            isValid: isTextAreaValid(action.payload),
            errorMessage: !isTextAreaValid(action.payload) ? textAreaErrorMessage(action.payload) : null,
          },
        },
      };
    default:
      return state;
  }
};
