import { Reducer } from "react";
import { ModalState } from "../correction-modal.typings";
import { JustificationAction, justificationReducer } from "./justification.reducer";
import { dealInfoReducer, DealInformationAction } from "./deal-information.reducer";

type CorrectionAction = DealInformationAction | JustificationAction;

const reduceReducers = <S, A>(...subReducers: Array<Reducer<S, A>>) => (state: S, action: A) =>
  subReducers.reduce((currentState, reducer) => reducer(currentState, action), state);

export const correctionReducer = reduceReducers<ModalState, CorrectionAction>(dealInfoReducer, justificationReducer);
