export const correctionFeatures = {
  correctionSubmitCreateCorrection: {
    category: "correctionStatuses",
    action: "correctionStatuses.correctionModal.create",
  },
  correctionSubmitUpdateCorrection: {
    category: "correctionStatuses",
    action: "correctionStatuses.correctionModal.update",
  },
  correctionListCreateCorrection: { category: "correctionStatuses", action: "correctionStatuses.correctionModal.open" },
};
