import { formatPercentage } from "common/helpers";
import { map, orderBy } from "lodash";
import { BpSharing, Correction, CorrectionDto } from "../components/entities.typings";

const bpSharingFormatter = (bpSharing: BpSharing[], propName: string): string => {
  if (bpSharing) {
    let sharing = bpSharing.filter(bp => bp[propName]);
    if (sharing.length === 0) {
      return "";
    }
    sharing = orderBy(
      sharing,
      [(bp: BpSharing) => bp[propName], (bp: BpSharing) => bp.bpClient.toLowerCase()],
      ["desc", "asc"]
    );
    return map(sharing, bp => `${bp.bpClient}/${formatPercentage(parseFloat(bp[propName]))}`).join(", ");
  }
  return "";
};

export const mapToCorrectionDTO = (corrections: CorrectionDto[]): Correction[] => {
  return corrections.map(correction => {
    return {
      correctionId: correction.correctionId,
      dealCode: correction.dealCode,
      status: correction.status,
      valueDate: correction.valueDate,
      ssBl: correction.ssBl,
      bookingEntity: correction.bookingEntity,
      originatingEntity: correction.originatingEntity,
      amountNbi: correction.correctionType === "nbi" ? correction.amount : "",
      oldAmountNbi: correction.correctionType === "nbi" ? correction.oldAmount : "",
      amountRwa: correction.correctionType === "rwa" ? correction.amount : "",
      oldAmountRwa: correction.correctionType === "rwa" ? correction.oldAmount : "",
      description: correction.description,
      justification: correction.justification,
      justificationFile: correction.justificationFile,
      lastModifiedBy: correction.lastModifiedBy,
      lastModificationDate: correction.lastModificationDate,
      createdBy: correction.createdBy,
      creationDate: correction.creationDate,
      category: correction.category,
      comments: correction.comments,
      correctionType: correction.correctionType,
      isDealCorrection: correction.isDealCorrection,
      bpSharing: correction.bpSharing,
      //pre-format data for grid => allows to sort and filter from string instead of object
      oldBpSharingText: bpSharingFormatter(correction.bpSharing, "oldShare"),
      newBpSharingText: bpSharingFormatter(correction.bpSharing, "share"),
    };
  });
};
