import React = require("react");
import { FC } from "react";
import { IconEdit } from "@dcc-cli/ic-shared-components";

export interface Props {
  value: string;
  valueFormatted: string;
  onEdit(): void;
  className?: string;
  direction?: "left" | "right";
}

export const EditableField: FC<Props> = (props: Props) => {
  const { onEdit, className, valueFormatted, value, direction } = props;
  const cellValue = valueFormatted ? valueFormatted : value;

  return (
    <div className={`d-flex ${direction === "right" ? "flex-row-reverse" : "flex-row"}`}>
      {cellValue !== "NaN" && <IconEdit size="sm" onClick={onEdit} className={`btn pb-2 ${className}`} />}
      {cellValue !== "NaN" ? cellValue : "-"}
    </div>
  );
};
