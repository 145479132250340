import { DEAL_SEARCH_MODE } from "enums/DealEnum";
import { some } from "lodash";
import { DealSearchFiltersState } from "./DealSearchFilters";

/**
 * checks that the deal filter search state is valid
 * @param dealSearchFiltersState
 * @param searchMode
 */
export const isValidFormState = (
  dealSearchFiltersState: DealSearchFiltersState,
  searchMode: DEAL_SEARCH_MODE
): boolean => {
  const datePickersFilters = [dealSearchFiltersState.startDate, dealSearchFiltersState.endDate];
  if (searchMode !== DEAL_SEARCH_MODE.otherCriteria) {
    return !some([dealSearchFiltersState.code, ...datePickersFilters], { isValid: false });
  }
  return (
    !some([dealSearchFiltersState.po.dropdownState, ...datePickersFilters], { isValid: false }) ||
    !some([dealSearchFiltersState.ssbl, ...datePickersFilters], { isValid: false }) ||
    (some([dealSearchFiltersState.originatingCountry, ...datePickersFilters], { isValid: true }) &&
      (!some([dealSearchFiltersState.po.dropdownState, ...datePickersFilters], { isValid: false }) ||
        !some([dealSearchFiltersState.ssbl, ...datePickersFilters], { isValid: false })))
  );
};
