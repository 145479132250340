import React = require("react");
import { FC } from "react";
import { Correction } from "../../../components/entities.typings";
import { Label } from "../../../components/common/ReadOnlyField/Label";
import { Button } from "../../../components/common/Buttons/Button";
import { DropDown } from "../../../components/common/DropDown/DropDown";
import { CorrectionStatus } from "../../../enums/CorrectionEnums";
import { IdAndLabel } from "../../../common/common.typings";
import { IconExport } from "@dcc-cli/ic-shared-components";
import {
  AUTHOR,
  CATEGORY,
  DESCRIPTION,
  COMMENTS,
  JUSTIFICATION,
  LAST_MODIFIED_BY,
  LAST_MODIFIED_DATE,
  STATUS,
} from "./correction-details.const";
import { get, isEmpty, isEqual, isNull, map, values } from "lodash";
import { FormField } from "../../../components/common/ReadOnlyField/FormField";
import { FileUploader } from "../../../components/common/FileUploader/FileUploader";
import { formatDate } from "../../../common/helpers";
import { InvalidFeedback } from "../../../components/common/ValidationMessage/InvalidFeedback";
import { isFormValid, isTextAreaValid, textAreaErrorMessage } from "./correction-details.helper";
import { CorrectionInTableState } from "./correction-details.typings";
import { CorrectionApi } from "../../../api/corrections.api";
import { userContext } from "../../../hooks/contexts/UserContext";
import { UserProfile } from "../../../enums/UserProfileEnum";

export interface Props {
  data: Correction;
  api: any;
  refresh(): void;
}

export const CorrectionDetails: FC<Props> = (props: Props) => {
  const { api, data, refresh } = props;
  const categories = api.categories;

  const DEFAULT_STATE = {
    Justification: { justification: data.justification, isValid: !isNull(data.justificationFile) },
    Description: { description: data.description, isValid: true, errorMessage: null },
    Status: { status: data.status },
    LastModifiedBy: { lastModifiedBy: data.lastModifiedBy },
    LastModificationDate: { lastModificationDate: data.lastModificationDate },
    Category: { category: data.category },
    Comments: {
      comments: data.comments,
      // Maybe the two checks below should be removed later when we will have correct data on database
      isValid: !isEmpty(data.comments),
      errorMessage: isEmpty(data.comments) ? "Please enter text" : null,
    },
    Author: { author: data.createdBy },
  };

  const [currentCorrectionState, setCurrentCorrectionState] = React.useState<CorrectionInTableState>(DEFAULT_STATE);
  const [fileRemoved, setFileRemoved] = React.useState(false);
  const [isDetailsFormValid, setIsDetailsFormValid] = React.useState<boolean>(false);
  const [statusOptions, setStatusOptions] = React.useState<IdAndLabel<string>[]>([]);

  const userProfileContext = React.useContext(userContext);
  const isSuperUser = get(userProfileContext, "userProfile") === UserProfile.SUPER_USER;

  const statusTransitions = get(
    get(userProfileContext, "transitions"),
    currentCorrectionState.Status.status.toLowerCase()
  );

  React.useEffect(() => {
    setIsDetailsFormValid(isFormValid(currentCorrectionState));
  }, [currentCorrectionState]);

  React.useEffect(() => {
    const options = map(statusTransitions, value => {
      const optionValue = values(CorrectionStatus).find(item => {
        if (item.toLowerCase() === value) {
          return item;
        }
        return null;
      });
      return {
        id: optionValue,
        label: optionValue,
      } as IdAndLabel<string>;
    });
    options && options.length ? setStatusOptions(options) : setStatusOptions([]);
  }, []);

  const onCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setCurrentCorrectionState({
      ...currentCorrectionState,
      Category: { category: event.target.value },
    });
  };

  const onStatusChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setCurrentCorrectionState({
      ...currentCorrectionState,
      Status: { status: event.target.value },
    });
  };

  const onValidatorCommentsChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const text = event.target.value;
    setCurrentCorrectionState({
      ...currentCorrectionState,
      Comments: {
        comments: text,
        isValid: isTextAreaValid(text),
        errorMessage: !isTextAreaValid(text) ? textAreaErrorMessage(text) : null,
      },
    });
  };

  const onDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const text = event.target.value;
    setCurrentCorrectionState({
      ...currentCorrectionState,
      Description: {
        description: event.target.value,
        isValid: isTextAreaValid(text),
        errorMessage: !isTextAreaValid(text) ? textAreaErrorMessage(text) : null,
      },
    });
  };

  const onDownloadFile = () => {
    CorrectionApi.downloadJustification(data.correctionId);
  };

  const handlePatchCorrection = async () => {
    await CorrectionApi.patchCorrection({
      correctionId: data.correctionId,
      description: currentCorrectionState.Description.description,
      justification: currentCorrectionState.Justification.justification,
      status: currentCorrectionState.Status.status,
      lastModifiedBy: data.lastModifiedBy,
      lastModificationDate: data.lastModificationDate,
      category: currentCorrectionState.Category.category,
      comments: currentCorrectionState.Comments.comments,
    }).then(() => refresh());
  };

  const onSelectFileChange = justification => {
    setCurrentCorrectionState({
      ...currentCorrectionState,
      Justification: { justification, isValid: true },
    });
  };
  const onFileRemove = () => {
    setCurrentCorrectionState({
      ...currentCorrectionState,
      Justification: { justification: null, isValid: false },
    });
    setFileRemoved(true);
  };

  return (
    <div className="row m-2 p-2">
      <div className="col-md-3 form-group">
        <FormField id={AUTHOR} label={AUTHOR} labelClassName="text-dark">
          <p>{currentCorrectionState.Author.author}</p>
        </FormField>
      </div>
      <div className="col-md-2 form-group">
        <FormField id={LAST_MODIFIED_DATE} label={LAST_MODIFIED_DATE} labelClassName="text-dark">
          <p>{formatDate(data.lastModificationDate) ?? "-"}</p>
        </FormField>
      </div>
      <div className="col-md-2 form-group">
        <Label displayLabel={LAST_MODIFIED_BY} htmlFor={LAST_MODIFIED_BY} className="text-dark" />
        <p>{data.lastModifiedBy}</p>
      </div>
      <div className="col-md-2 form-group">
        <Label displayLabel={CATEGORY} htmlFor={CATEGORY} className="text-dark" />
        <DropDown
          className={"text-center"}
          selectedValue={currentCorrectionState.Category.category}
          options={categories}
          required={true}
          onChange={onCategoryChange}
          disabled={!isSuperUser}
        />
      </div>
      <div className="col-md-2 form-group">
        <Label displayLabel={STATUS} htmlFor={STATUS} className="text-dark" />
        <DropDown
          className="text-center"
          selectedValue={currentCorrectionState.Status.status}
          options={statusOptions}
          onChange={onStatusChange}
          disabled={!statusOptions.length}
        />
      </div>
      <div className="col-md-3 form-group">
        <FormField id={JUSTIFICATION} label={JUSTIFICATION} labelClassName="text-dark">
          {data.justificationFile ? (
            <>
              <div className="row">
                <div className="col-md-6">
                  <FileUploader
                    isValid={true}
                    onFileSelectSuccess={file => onSelectFileChange(file)}
                    onFileRemove={() => onFileRemove()}
                    fileAlreadyExist={data.justificationFile != null ? data.justificationFile : null}
                    placeholder="Drag file"
                  />
                </div>
                <div className="col-md-6">
                  <Button
                    text="Download"
                    className="btn btn-md btn-success"
                    onClick={onDownloadFile}
                    disabled={currentCorrectionState.Justification.isValid === false || fileRemoved}
                  >
                    <IconExport className="ml-2" />
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <p>No justification provided</p>
          )}
        </FormField>
      </div>
      <div className="col-md-4 form-group">
        <FormField id={DESCRIPTION} label={DESCRIPTION} labelClassName="text-dark">
          <textarea
            className={`form-control ${currentCorrectionState.Description.isValid ? "" : "is-invalid"}`}
            onChange={onDescriptionChange}
            value={currentCorrectionState.Description.description}
            placeholder={DESCRIPTION}
            rows={3}
            cols={35}
            maxLength={500}
            disabled={!isSuperUser}
          />
          {!currentCorrectionState.Description.isValid && (
            <InvalidFeedback errorMessage={currentCorrectionState.Description.errorMessage} errorType={"danger"} />
          )}
        </FormField>
      </div>
      <div className="col-md-4 form-group">
        <Label displayLabel={COMMENTS} htmlFor={COMMENTS} className="text-dark" />
        <textarea
          className={`form-control ${currentCorrectionState.Comments.isValid ? "" : "is-invalid"}`}
          onChange={onValidatorCommentsChange}
          value={currentCorrectionState.Comments.comments}
          placeholder={COMMENTS}
          rows={3}
          cols={35}
          maxLength={500}
          disabled={!statusOptions.length}
        />
        {!currentCorrectionState.Comments.isValid && (
          <InvalidFeedback errorMessage={currentCorrectionState.Comments.errorMessage} errorType={"danger"} />
        )}
      </div>
      <div className="col-md-1 text-right mt-4">
        <Button
          text="Submit"
          onClick={handlePatchCorrection}
          className="btn-lg btn-danger mt-4 mr-n2"
          disabled={isEqual(currentCorrectionState, DEFAULT_STATE) || !isDetailsFormValid}
        />
      </div>
    </div>
  );
};
