import { JustificationSectionState } from "components/Correction/CorrectionsList/correction-details.typings";
import { DealInformationSectionState, ModalState } from "../correction-modal.typings";

export const DEAL_INFORMATION_SECTION_DEFAULT_STATE: DealInformationSectionState = {
  OriginatingEntity: { isValid: false },
  BookingEntity: { isValid: false },
  Ssbl: { isValid: false },
  BpSharing: [
    {
      Client: { isValid: false },
      Sharing: { isValid: true, input: "100%" },
      OldSharing: "0",
    },
  ],
  PoClient: { isValid: false },
  AgencyName: { isValid: true },
  ProductLabel: { isValid: true },
  ProfitCenter: { isValid: false },
  Amount: { isValid: false },
  Indicator: {
    isValid: false,
  },
  CorrectionType: {
    correctionType: "nbi",
    isValid: true,
  },
  ValueDate: { isValid: false },
  IsIB: { isValid: true, value: false },
};

export const JUSTIFICATION_SECTION_DEFAULT_STATE: JustificationSectionState = {
  Justification: { isValid: false },
  Description: { isValid: false, description: "" },
};

export const DEFAULT_STATE: ModalState = {
  DealInformationSectionState: DEAL_INFORMATION_SECTION_DEFAULT_STATE,
  JustificationSectionState: JUSTIFICATION_SECTION_DEFAULT_STATE,
};
export const VALUE_MONTH = "Value month";
export const ERRROR_MESSAGE_A = "Please select a";
export const AGENCY_NAME = "Agency name";
export const ORIGINATING_ENTITY = "Originating entity";
export const ORIGINATING_COUNTRY = "Originating country";
export const BOOKING_ENTITY = "Booking entity";
export const SUB_SUB_BUSINESS_LINE = "Sub sub business line";
export const BP = "Business provider";
export const PO = "Primary obligor";
export const PRODUCT_LABEL = "Product";
export const PROFIT_CENTER = "Profit center";
export const AMOUNT = "Amount (€)";
export const NEW_AMOUNT = "New amount (€)";
export const NEW_AMOUNT_IF_VALIDATED = "New amount (€) (if validated)";
export const JUSTIFICATION = "Justification";
export const DESCRIPTION = "Description";
export const INDICATOR = "Indicator";
export const SHARING = "Sharing (%)";
export const DEAL_CODE = "Deal code";
export const IS_IB = "Is IB";
