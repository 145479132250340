import { FC } from "react";
import React = require("react");
import { AgGridColumn } from "ag-grid-react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Deal } from "components/entities.typings";
import { GridReadyEvent } from "ag-grid-community";
import { decimalFormatter } from "../../../common/helpers";
import { EditableField } from "./EditableField";
import { startsWith } from "lodash";

export interface Props {
  deals: Deal[];
  totalPendingNbi: number;
  totalOldNbi: number;
  totalNBI: number;
  onGridReady(event: GridReadyEvent): void;
  onEditNbi(deal: Deal): void;
  onEditRwa(deal: Deal): void;
  onEditBp(deal: Deal): void;
}

export const DealsTable: FC<Props> = (props: Props) => {
  const { deals, onGridReady, onEditNbi, onEditRwa, onEditBp, totalPendingNbi, totalOldNbi, totalNBI } = props;
  const amountCellStyle = { textAlign: "right" };
  const gridStyle = { height: "600px", marginBottom: "100px" };
  const gridRef = React.useRef<AgGridReact>();

  const renderTotalPendingNbi = () => {
    const amountPending = totalPendingNbi > 0 ? totalNBI - totalOldNbi + totalPendingNbi : totalNBI;
    return (
      <>
        <div className="ag-status-name-value" hidden={deals.length === 0 ? true : false}>
          <label className="font-weight-bold">{`Total NBI: ${decimalFormatter(totalNBI)} €`}</label>
        </div>
        <div className="ag-status-name-value" hidden={deals.length === 0 ? true : false}>
          <label className="font-weight-bold">{`Total NBI (pending validation): ${decimalFormatter(
            amountPending
          )} €`}</label>
        </div>
      </>
    );
  };

  const editableBusinessProviderComponent = (params) => {
    return startsWith(params.data.dealCode, "DPO") || startsWith(params.data.dealCode, "DBP") ? (
      params.value
    ) : (
      <EditableField
        onEdit={() => onEditBp(params.data)}
        value={params.value}
        valueFormatted={params.valueFormatted}
        className="px-0 mr-0"
      />
    );
  };

  const editableNbiComponent = (params) => {
    return (
      <EditableField
        onEdit={() => onEditNbi(params.data)}
        value={params.value}
        valueFormatted={params.valueFormatted}
        className="pl-1 pr-0 mr-0"
        direction="right"
      />
    );
  };

  const editableRwaComponent = (params) => {
    return (
      <EditableField
        onEdit={() => onEditRwa(params.data)}
        value={params.value}
        valueFormatted={params.valueFormatted}
        className="pl-1 pr-0 mr-0"
        direction="right"
      />
    );
  }

  return (
    <>
      <div className="ag-theme-alpine" style={gridStyle}>
        <AgGridReact
          ref={gridRef}
          key={totalNBI}
          defaultColDef={{
            sortable: true,
            resizable: true,
          }}
          rowData={deals}
          pagination={true}
          paginationPageSize={15}
          onGridReady={onGridReady}
          statusBar={{
            statusPanels: [
              {
                statusPanel: renderTotalPendingNbi,
                align: "left",
              },
            ],
          }}
        >
          <AgGridColumn field="dealCode" headerName="Deal code" width={120} minWidth={50} />
          <AgGridColumn field="valueMonth" headerName="Value month" width={130} />
          <AgGridColumn field="ssBl" headerName="SSBL" width={85} minWidth={50} tooltipField="ssBl" />
          <AgGridColumn field="pc" headerName="Profit center" width={130} minWidth={50} />
          <AgGridColumn
            field="providerBusiness"
            headerName="Business provider"
            width={160}
            tooltipField="providerBusiness"
            cellRenderer={editableBusinessProviderComponent}
          />
          <AgGridColumn field="po" headerName="Primary obligor" width={150} minWidth={50} tooltipField="po" flex={1} />
          <AgGridColumn field="be" headerName="Booking entity" width={145} minWidth={50} tooltipField="be" flex={1} />
          <AgGridColumn
            field="oe"
            headerName="Originating entity"
            width={165}
            minWidth={50}
            tooltipField="oe"
            flex={1}
          />
          <AgGridColumn
            field="product"
            headerName="Product"
            width={100}
            minWidth={50}
            tooltipField="product"
            flex={1}
          />
          <AgGridColumn field="agency" headerName="Agency" width={100} minWidth={50} flex={1} />
          <AgGridColumn field="sourceApp" headerName="Deal source" width={125} minWidth={50} flex={1} />
          <AgGridColumn field="supplier" headerName="Deal supplier" width={135} minWidth={50} flex={1} />
          <AgGridColumn field="indicator" headerName="Indicator" width={110} minWidth={50} flex={1} />
          <AgGridColumn field="isIb" headerName="Is IB" width={85} minWidth={50} flex={1} />
          <AgGridColumn
            field="nbi"
            headerName="NBI (€)"
            width={120}
            minWidth={100}
            flex={1}
            cellStyle={amountCellStyle}
            valueFormatter={params => decimalFormatter(params.value)}
            cellRenderer={editableNbiComponent}
          />
          <AgGridColumn
            field="rwa"
            headerName="RWA (€)"
            width={125}
            minWidth={100}
            flex={1}
            cellStyle={amountCellStyle}
            valueFormatter={params => decimalFormatter(params.value)}
            cellRenderer={editableRwaComponent}
          />
        </AgGridReact>
      </div>
    </>
  );
};
