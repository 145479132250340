import { formatPercentage } from "common/helpers";
import { Deal, DealDto } from "components/entities.typings";
import { map } from "lodash";

export const mapToGrid = (dealDto: DealDto): Deal => {
  return {
    dealCode: dealDto.dealCode,
    valueMonth: dealDto.valueMonth,
    ssBl: dealDto.ssBl,
    be: dealDto.be,
    oe: dealDto.oe,
    nbi: dealDto.nbi,
    rwa: dealDto.rwa,
    agency: dealDto.agency,
    providerBusiness: map(dealDto.bpSharing, value => {
      if (value.sharingPercentage) {
        return `${value.clientLe}/${formatPercentage(value.sharingPercentage)}`;
      }
      return value;
    }).join(", "),
    bpSharing: dealDto.bpSharing,
    po: dealDto.poLeName,
    poLeId: dealDto.poLeId,
    product: dealDto.product,
    indicator: dealDto.indicator,
    pc: dealDto.pc,
    supplier: dealDto.supplier.join(", "),
    sourceApp: dealDto.sourceApp,
    isIb: dealDto.isIb,
  };
};
