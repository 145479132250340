import { FC } from "react";
import React = require("react");
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Correction } from "../../../components/entities.typings";
import { FilterChangedEvent, GridReadyEvent } from "ag-grid-community";
import { CorrectionStatus } from "../../../enums/CorrectionEnums";
import { CorrectionEditAction } from "./CorrectionEditAction";
import { CorrectionDetails } from "./CorrectionDetails";
import { decimalFormatter, formatDate, formatDateToPeriodDisplay } from "../../../common/helpers";
import CorrectionEditor from "../CorrectionEditor/CorrectionEditor";
import { CorrectionApi } from "../../../api/corrections.api";
import { agGridLicense } from "utils/ag-grid-license";
import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey(agGridLicense);

export interface Props {
  corrections: Correction[];
  userProfile?: string;
  onGridReady(event: GridReadyEvent): void;
  onFilterTextBoxChanged(event: FilterChangedEvent): void;
  refresh(): void;
}

export const CorrectionsStatusTable: FC<Props> = (props: Props) => {
  const { corrections, onGridReady, onFilterTextBoxChanged, refresh } = props;

  const [showModal, setShowModal] = React.useState(false);
  const [correction, setCorrection] = React.useState(null);

  const onEditRowClick = (correctionId: number) => {
    CorrectionApi.getCorrection(correctionId).then(result => {
      setCorrection(result);
      setShowModal(true);
    });
  };

  const onCloseModalClick = () => {
    setShowModal(false);
  };

  const amountCellStyle = { textAlign: "right" };
  const gridMarginStyle = { marginBottom: "100px" };

  const formatValue = (value: string): string => (value !== "" ? decimalFormatter(parseFloat(value)) : "-");
  const checkValue = (value: string): string => (value ? value : "-");

  const statusBadgeComponent = (status) => (<span className={`badge badge-${getCorrectionStatusType(status.value)}`}>{status.value}</span>);

  const editActionComponent = (params) => (
    <CorrectionEditAction
      onclick={() => {
        onEditRowClick(params.data.correctionId);
      }}
      status={params.data.status}
    />
  )

  return (
    <>
      <CorrectionEditor
        showModalWindow={showModal}
        onCloseModalClick={onCloseModalClick}
        correction={correction}
        onCorrectionCreation={refresh}
      />
      <div className="ag-theme-alpine" style={gridMarginStyle}>
        <AgGridReact
          masterDetail={true}
          defaultColDef={{
            sortable: true,
            resizable: true,
            filter: true,
            width: 60,
            minWidth: 50,
          }}
          domLayout="autoHeight"
          rowData={corrections}
          pagination={true}
          paginationPageSize={15}
          onGridReady={onGridReady}
          onFilterChanged={onFilterTextBoxChanged}
          detailCellRenderer={"detailCellRenderer"}
          detailRowAutoHeight={true}
          detailCellRendererParams={{
            refresh: () => refresh(),
          }}
          components={{
            correctionEditCellRenderer: CorrectionEditAction,
            detailCellRenderer: CorrectionDetails,
          }}
        >
          <AgGridColumn
            field="correctionId"
            headerName="ID"
            width={100}
            minWidth={40}
            sort="desc"
            cellRenderer="agGroupCellRenderer"
            flex={1}
          />
          <AgGridColumn field="createdBy" headerName="Created By" width={150} tooltipField="createdBy" flex={1} />
          <AgGridColumn field="dealCode" headerName="Deal code" width={150} tooltipField="dealCode" flex={1} />
          <AgGridColumn
            field="creationDate"
            valueFormatter={date => formatDate(date.value)}
            headerName="Creation date"
            width={135}
            flex={1}
          />
          <AgGridColumn
            field="valueDate"
            valueFormatter={date => formatDateToPeriodDisplay(date.value)}
            headerName="Value month"
            width={130}
            flex={1}
          />
          <AgGridColumn field="ssBl" headerName="SSBL" width={140} minWidth={50} tooltipField="ssBl" flex={1} />
          <AgGridColumn
            headerName="Old BP sharing"
            field="oldBpSharingText"
            tooltipField="oldBpSharingText"
            valueFormatter={params => checkValue(params.value)}
            width={240}
            flex={1}
          />
          <AgGridColumn
            headerName="New BP sharing"
            field="newBpSharingText"
            tooltipField="newBpSharingText"
            valueFormatter={params => checkValue(params.value)}
            width={240}
            flex={1}
          />
          <AgGridColumn
            field="oldAmountNbi"
            headerName="Old NBI (€)"
            cellStyle={amountCellStyle}
            valueFormatter={params => formatValue(params.value)}
            width={135}
            flex={1}
          />
          <AgGridColumn
            field="amountNbi"
            headerName="New NBI (€)"
            cellStyle={amountCellStyle}
            valueFormatter={params => formatValue(params.value)}
            width={135}
            flex={1}
          />
          <AgGridColumn
            field="oldAmountRwa"
            headerName="Old RWA (€)"
            cellStyle={amountCellStyle}
            valueFormatter={params => formatValue(params.value)}
            width={135}
            flex={1}
          />
          <AgGridColumn
            field="amountRwa"
            headerName="New RWA (€)"
            cellStyle={amountCellStyle}
            valueFormatter={params => formatValue(params.value)}
            width={135}
            flex={1}
          />
          <AgGridColumn
            field="status"
            cellRenderer={statusBadgeComponent}
            headerName="Status"
            width={100}
            resizable={false}
            flex={1}
          />
          <AgGridColumn
            headerName="Edit"
            field="correctionId"
            cellRenderer={editActionComponent}
            width={80}
            minWidth={20}
            resizable={false}
            flex={1}
          />
        </AgGridReact>
      </div>
    </>
  );
};

const getCorrectionStatusType = (status: CorrectionStatus) => {
  switch (status) {
    case CorrectionStatus.CANCELLED:
      return "warning";
    case CorrectionStatus.VALIDATED:
      return "success";
    case CorrectionStatus.REJECTED:
      return "danger";
    case CorrectionStatus.PROPOSED:
      return "secondary";
    default:
      return "warning";
  }
};