import { Button } from "components/common/Buttons/Button";
import { FormField } from "components/common/ReadOnlyField/FormField";
import { InvalidFeedback } from "components/common/ValidationMessage/InvalidFeedback";
import { Referential } from "components/entities.typings";
import React = require("react");
import { BpSharingState, ReferentialProps } from "../correction-modal.typings";
import { BP, SHARING } from "../utils/correction-modal.const";
import { getValidationErrorMessage } from "../utils/correction-modal.helper";
import ReferentialPicker from "./ReferentialPicker";

export interface BpClientsRendererProps {
  BpSharing: BpSharingState[];
  isDealCorrection: boolean;
  isBpCorrection: boolean;
  onSelect: (value: Referential, propName: ReferentialProps, index?: number) => void;
  onAddBp?: () => void;
  removeBpClient?: (index: number) => void;
  onSharingSelect?: (item: string, index: number) => void;
}

const BpClientsRenderer: React.FC<BpClientsRendererProps> = ({
  BpSharing,
  isDealCorrection,
  isBpCorrection,
  onSelect,
  onAddBp,
  removeBpClient,
  onSharingSelect,
}: BpClientsRendererProps) => {
  const addBpClient = (): void => {
    if (!BpSharing.some(element => element.Client.selected?.id === "")) {
      onAddBp();
    }
  };

  const bpCorrectionInputMarginStyle: string = isBpCorrection ? "mb-1" : "mb-0";

  return (
    <>
      {BpSharing.map((bpSharing, index) => {
        return (
          <React.Fragment key={index}>
            <div className={`${isBpCorrection ? "col-md-8" : "col-md-6"} form-group`}>
              <ReferentialPicker
                label={BP}
                labelClassName={`text-secondary ${bpCorrectionInputMarginStyle}`}
                key={index}
                displayLabel={index === 0}
                disabled={isDealCorrection && !isBpCorrection}
                isValid={bpSharing.Client.isValid}
                selected={bpSharing.Client.selected}
                onSelect={selected => onSelect(selected, "BpSharing", index)}
                includeKeys={true}
              />
            </div>
            {BpSharing.length > 1 && (
              <>
                <div className={`${isBpCorrection ? "col-md-2" : "col-md-6"} mb-1 form-group`}>
                  {index === 0 ? (
                    <FormField
                      id={SHARING}
                      label={SHARING}
                      labelClassName={`text-secondary ${bpCorrectionInputMarginStyle}`}
                    >
                      <input
                        className={`form-control ${bpSharing?.Sharing.isValid ? "" : "is-invalid"}`}
                        placeholder={SHARING}
                        onChange={e => onSharingSelect(e.target.value, index)}
                        required={true}
                        value={bpSharing?.Sharing.input}
                        disabled={isDealCorrection && !isBpCorrection}
                      />
                    </FormField>
                  ) : (
                    <input
                      className={`form-control ${bpCorrectionInputMarginStyle} ${
                        bpSharing?.Sharing.isValid ? "" : "is-invalid"
                      }`}
                      placeholder={SHARING}
                      onChange={e => onSharingSelect(e.target.value, index)}
                      required={true}
                      value={bpSharing?.Sharing.input}
                      disabled={isDealCorrection && !isBpCorrection}
                    />
                  )}
                </div>
                {isDealCorrection && isBpCorrection && (
                  <div className={`col-md-2 form-group ${index === 0 ? "mt-4" : ""}`}>
                    <Button
                      text="Delete"
                      className="btn btn-secondary form-control"
                      onClick={() => {
                        removeBpClient(index);
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </React.Fragment>
        );
      })}
      {isBpCorrection && (
        <div className={`${BpSharing.length === 1 ? "col-md-12" : "col-md-8"} form-group`}>
          <Button text="Add BP" className="btn btn-secondary" onClick={addBpClient}></Button>
        </div>
      )}
      {isBpCorrection && getValidationErrorMessage(BpSharing) !== "" && (
        <div className="col-md-4 form-group">
          <InvalidFeedback errorMessage={getValidationErrorMessage(BpSharing)} errorType="danger" />
        </div>
      )}
      {isDealCorrection && BpSharing.length <= 1 && <div className="col-md-6 form-group" />}
    </>
  );
};

export default BpClientsRenderer;
