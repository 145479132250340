import { percentageToNumber } from "common/helpers";
import { BpSharing, Correction } from "components/entities.typings";
import { CorrectionStatus, CorrectionType } from "enums/CorrectionEnums";
import { get, map } from "lodash";
import { BpSharingState, ModalState } from "../correction-modal.typings";

function mapModalToCorrection(modalState: ModalState): Correction {
  return {
    bookingEntity: modalState.DealInformationSectionState.BookingEntity.selected.label,
    originatingEntity: modalState.DealInformationSectionState.OriginatingEntity.selected.label,
    ssBl: modalState.DealInformationSectionState.Ssbl.selected.label,
    bpSharing: map(
      modalState.DealInformationSectionState.BpSharing,
      (bp: BpSharingState): BpSharing => {
        return {
          bpClient: bp.Client.selected.label,
          bpClientId: bp.Client.selected.id,
          share: percentageToNumber(bp.Sharing.input).toString(),
          oldShare: percentageToNumber(bp.OldSharing).toString(),
        };
      }
    ),
    poClient: modalState.DealInformationSectionState.PoClient.selected.label,
    poClientId: modalState.DealInformationSectionState.PoClient.selected.id,
    valueDate: modalState.DealInformationSectionState.ValueDate.date,
    profitCenter: modalState.DealInformationSectionState.ProfitCenter.selected.label,
    agencyName: get(modalState.DealInformationSectionState.AgencyName, "selected")
      ? modalState.DealInformationSectionState.AgencyName.selected.label
      : "NA",
    productLabel: get(modalState.DealInformationSectionState.ProductLabel, "selected")
      ? modalState.DealInformationSectionState.ProductLabel.selected.label
      : "NA",
    amount: modalState.DealInformationSectionState.Amount.amount ?? "",
    justification: modalState.JustificationSectionState.Justification.justification,
    description: modalState.JustificationSectionState.Description.description,
    correctionType: modalState.DealInformationSectionState.CorrectionType.correctionType.toLowerCase() as CorrectionType,
    isIb: modalState.DealInformationSectionState.IsIB.value,
  };
}

export function mapModalStateToUpdateCorrection(modalState: ModalState, correctionId: number): Correction {
  return {
    ...mapModalToCorrection(modalState),
    correctionId,
    indicator: modalState.DealInformationSectionState.Indicator.selected.label,
    indicatorId: modalState.DealInformationSectionState.Indicator.selected.id,
  };
}

export function mapModalStateToCreateCorrection(modalState: ModalState, dealCode: string): Correction {
  return {
    ...mapModalToCorrection(modalState),
    dealCode,
    indicator: get(modalState.DealInformationSectionState.Indicator, "selected")
      ? modalState.DealInformationSectionState.Indicator.selected.label
      : "",
    indicatorId: get(modalState.DealInformationSectionState.Indicator, "selected")
      ? modalState.DealInformationSectionState.Indicator.selected.id
      : null,
    status: CorrectionStatus.PROPOSED,
  };
}
