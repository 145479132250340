import { get, noop } from "lodash";
import * as React from "react";
import { dateFormatIso8601, defaultDateFormat } from "./dateFormats";

export interface Props {
  name: string;
  dateFormat?: string;
  type?: "all" | "date" | "time";
  viewMode?: string;
  value: string;
  isValid?: boolean;
  displayDateFormat?: string;
  disabled?: boolean;
  onChange?: (newValue?: Date | string) => void;
}

const SgwtDatePicker: React.FC<Props> = ({
  name,
  dateFormat = dateFormatIso8601,
  displayDateFormat = defaultDateFormat,
  type = "date",
  viewMode,
  value,
  isValid,
  disabled,
  onChange = noop,
}: Props) => {
  const ref = React.useRef(null);
  const onSelectionDateChanged = (event: any) => {
    const selectedDate = get(event, "detail.value", null);
    onChange(selectedDate);
  };

  React.useEffect(() => {
    if (!disabled) {
      ref.current.addEventListener("sgwt-datetime-picker--datetime-updated", onSelectionDateChanged);
    }
    return () => {
      if (ref?.current && !disabled) {
        ref.current.removeEventListener("sgwt-datetime-picker--datetime-updated", onSelectionDateChanged);
      }
    };
  });

  return (
    <>
      {disabled ? (
        <input
          type="text"
          className="form-control date datepicker-holder"
          disabled={true}
          placeholder={displayDateFormat}
          value={value}
          onChange={noop}
        />
      ) : (
        <sgwt-datetime-picker
          ref={ref}
          date-format={dateFormat}
          display-date-format={displayDateFormat}
          type={type}
          keyName={name}
          value={value}
          view-mode={viewMode}
          is-valid={isValid}
        />
      )}
    </>
  );
};

export default SgwtDatePicker;
